@use '../../assets/styles/variables';
@use '../../assets/styles/partials/mosaic';
@use '../../assets/styles/partials/carousel';

.block-venueShowcase {
  .venue-links {
    a {
      font-weight: 600;
    }

    ul {
      margin: 0;
    }

    ul + ul {
      margin-top: calc(var(--g-gap) * 0.5);
    }
  }

  > article > div > div {
    .button {
      margin-top: var(--g-gap);
    }
  }

  .card {
    gap: var(--g-gap);

    > div {
      display: grid;
      gap: calc(var(--g-gap) * 0.5);

      > h1 {
        font-weight: 700;
      }

      > main > p:first-of-type {
        margin-top: 0;
      }
    }

    footer {
      button {
        border: none;
        background: none;
        color: inherit;
        font: inherit;
        padding: 0;
        width: 100%;
        display: block;
        text-align: left;
      }

      button,
      a {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 0.5em 0.25em;
      }

      svg {
        width: 1em;
        height: auto;
      }

      > :first-child {
        border-top: 1px solid var(--c-goma-grey);
      }
    }
  }
}

.venue-details {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin: 0;
  gap: 1em;

  li {
    align-items: center;
    display: flex;
    gap: 0.5em;
  }

  span {
    @include variables.sr-only;
  }

  svg {
    max-height: 1em;
    max-width: 1.25em;
    width: 100%;
  }
}
