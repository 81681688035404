@use '../variables';

.header {
  backdrop-filter: blur(0.5em);
  background: rgba(white, 0.95);
  position: sticky;
  top: 0;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  z-index: 1000;

  &:not(.top):not(.open) {
    box-shadow: 0 0 6px rgba(black, 0.15);
  }

  &.hidden:not(.open) {
    transform: translateY(-100%);
    transition-duration: 0.5s;
    transition-delay: 0.5s;
  }

  &__inner {
    display: grid;
    align-items: center;
    gap: 1em;
    grid-template-columns: 1fr;
    grid-auto-flow: column;
    padding: 1.2em calc((100vw - var(--container-width)) / 2);
    margin: 0 var(--g-gap);
  }

  &__brand {
    color: var(--c-goma-grey);
    margin: 0 auto 0 0;

    a {
      color: inherit;
    }

    a,
    svg,
    img {
      display: block;
    }

    svg,
    img {
      width:calc(100% - var(--g-gap));
      max-width:16.5em;
      width: clamp(calc(100% - var(--g-gap)), 40vw, 16.5em);
    }

    svg {
      fill: currentColor;
    }
  }
}

.alert {
  text-align: center;
  padding: calc(var(--g-gap) * 0.25) var(--g-gap);

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    display: block;

    &:not(:last-child) {
      border-bottom: 1px solid var(--c-off-white);
    }
  }

  a {
    font-weight: 600;

    &:last-child {
      display: inline-grid;
      grid-auto-flow: column;
      align-items: center;

      &::after {
        content: '';
        display: inline-block;
        background: url('data-url:/assets/svgs/arrow-right.svg') center / contain no-repeat;
        height: 1em;
        margin-left: 0.4em;
        transform: translateY(0.1em);
        width: 1.1em;
      }
    }
  }
}

.search {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  &-toggle {
    background: none;
    border: none;
    cursor: pointer;
    display: grid;
    padding: 0;

    img {
      width: 2em;
    }

    svg {
      width: 1.5em;
    }
  }

  &-results {
    background: rgba(255, 255, 255, 0.98);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 7rem 0rem;

    svg {
      width: 1em;
    }

    &-enter {
      transition: top 0.3s ease;
      transition-delay: 0.2s;

      &-start {
        opacity: 0;
      }

      &-end {
        opacity: 1;
      }
    }

    &-leave {
      transition: top 0.3s ease;
      transition-delay: 0.1s;

      &-start {
        opacity: 1;
      }

      &-end {
        opacity: 0;
      }
    }

    &-content { 
      overflow-y: auto;
      overscroll-behavior-y: contain;
      height: 100%;

      &-inner {
        width: clamp(0px, 1400px, calc(100vw - 3rem));
        margin: auto;
        padding-bottom: 2rem;
      }

      &-suggestions {
        &-title {
          font-weight: 650;
          font-size: 1.35rem;
        }

        &-container {
          display: flex;
          padding-top: 1.5rem;
          gap: 1.5rem;
          place-content: space-between;
          flex-wrap: wrap;
          gap: 0.75rem;
          font-size: 0.8rem;
          display: flex;
        }
      }

      &-suggestion {
        background-color: #f2f2f4;
        padding: 0.7rem 1.5rem;
        font-weight: 500;
        cursor: pointer;
        text-decoration: none;
        font-size: 0.8rem;
        flex: 1 1 auto;

        @media (min-width: 70rem) {
          font-size: 1.2rem;
          flex: 0 1 auto;
        }
      }
    }
  }

  &-control {
    &-enter {
      transition: top 0.3s ease;
      transition-delay: 0.2s;

      &-start {
        top: -100px !important;
      }

      &-end {
        top: 0px !important;
      }
    }

    &-leave {
      transition: top 0.3s ease;
      transition-delay: 0.1s;

      &-start {
        top: 0px !important;
      }

      &-end {
        top: -100px !important;
      }
    }
  }

  &-controls {
    background: rgba(255, 255, 255, 0.98);
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
    position: absolute;
    z-index: 10;
    top: 0px;
    width: 100%;
    display: flex;

    &-actions {
      display: flex;
      gap: 1rem;
    }

    &-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1em;
      padding: 1.7em calc((100vw - var(--container-width)) / 2);
      margin: 0 var(--g-gap);
      width: 100%;
    }

    &-input {
      flex: 1 1 auto;

      input {
        width: 100%;
        font-size: 1.2rem;
        font-weight: 650;
        border: none;
        color: var(--c-goma-grey);

        &:focus {
          outline: none;
        }
      }
    }

    &-action {
      cursor: pointer;
    }
  }

  &-hit {
    position: relative;

    & > * {
      position: relative;
      width: 100%;
    }

    h2 {
      line-height: 1.3rem;
    }

    &-group {
      padding-bottom: 0.75rem;
      font-size: 0.8rem;
      font-weight: 500;
      text-decoration: underline;
    }

    &-subtitle {
      font-weight: 400;
      font-size: 1rem;
    }

    &-detail {
      text-decoration: underline;
      font-size: 0.8em;
      font-weight: 500;
    }

    &-sub {
      margin-top: -0.25rem;
      padding-left: 1.75rem;
      position: relative;

      &.standin {
        margin-top: 0;
        padding-left: 0;

        &::before {
          display: none;
        }
      }

      &::before {
        position: absolute;
        left: 0;
        top: 0.75rem;
        width: 1rem;
        height: 0.25rem;
        background: black;
        content: '';
        transform: translateY(-50%);
      }
    }

    &-image {
      background: #f2f2f4;
    }

    .card__image {
      min-height: 230px;
      place-content: center;
      place-items: center;
      aspect-ratio: unset;
      margin-bottom: 1.5rem;

      img {
        max-height: 230px;
      }
    }
  }

  &-pagination {
    &-wrapper {
      display: flex;
      gap: 0.5rem;
      place-content: center;
    }

    &-item {
      padding: 0.8rem;
      background-color: var(--c-off-white);
      cursor: pointer;

      &.active {
        background-color: var(--c-goma-grey);
        color: var(--c-off-white);
      }
    }
  }

  &.search-page {
    height: auto;
  }

  &-page {
    .search {
      &-submit {
        display: none;
      }
    }

    &-header {
      font-size: 3.75rem;
      font-weight: 800;
      margin-bottom: 3rem;
      line-height: 1em;
    }

    &-chips {
      margin-bottom: 4rem;

      &-inner {
        display: flex;
        gap: 0.75rem;
        font-size: 0.8rem;
        flex-wrap: wrap;

        @media (min-width: 38em) {
          font-size: 1rem;
        }
      }
    }

    &-chip {
      background: var(--c-off-white);
      color: var(--c-goma-grey);
      padding: 0.7rem 1.5rem;
      cursor: pointer;
      flex: 1 1 auto;

      @media (min-width: 38em) {
        flex: unset;
      }

      &.active {
        background: var(--c-goma-grey);
        color: var(--c-off-white);
      }
    }

    &-no-results {
      &-inner {
        display: flex;
        flex-direction: column;
        place-content: center;
        place-items: center;
        gap: 1rem;
        margin-top: 2rem;
      }

      &-heading {
        font-size: 2.25rem;
        font-weight: 800;
      }

      &-sub {
        font-size: 1.2rem;
      }

      &-inner {
        svg {
          width: 14.6em;
          margin-bottom: 0.5rem;
          fill: none;
        }
      }
    }
  }

  &-standin {
    width: 100%;
    height: 1rem;
    overflow: hidden;
  
    &-inner {
      animation: backgroundPulse 2s ease infinite alternate-reverse;
      height: 100%;
      background: linear-gradient(90deg, #f2f2f4, #ccc, #f2f2f4);
      background-size: 600%;
    }
  
    &.image {
      height: 100%;
    }
  
    &.tiny {
      width: 12.5%;
    }
  
    &.small {
      width: 25%;
    }
  
    &.medium {
      width: 50%;
    }
  
    &.large {
      width: 75%;
    }
  
    &.full {
      width: 100%;
    }
  
    &.tall {
      height: 1.4rem;
    }
  
    &.x-tall {
      height: 2.9rem;
    }
  }
}

.quick {
  &-search-hits {
    a {
      text-decoration: none;
    }

    h2 {
      margin: 0.2rem 0;
      font-size: 1.2rem;
      font-weight: 600;
    }
  }

  &-stats {
    padding-bottom: 0.75rem;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
  }
}

.result {
  &-header {
    width: clamp(0px, 1400px, calc(100vw - 3rem));
    margin: auto;
    padding-bottom: 2rem;
    font-weight: 750;
    gap: 1rem;

    @media (min-width: 70rem) {
      display: flex;
      place-content: space-between;
      place-items: baseline;
    }

    &-copy {
      font-size: 2.25rem;
    }

    &-divider {
      flex: 1 1 auto;
      @media (min-width: 70rem) {
        border-bottom: 2px solid;
      }
    }

    &-action {
      font-size: 1.25rem;
      cursor: pointer;
    }
  }
}

.no-image {
  position: relative;

  img {
    display: none;
  }

  &::after {
    content: 'No image available';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.8rem;
    opacity: 0.5;
  }
}

.hit-container {
  display: grid;
  width: clamp(0px, 1400px, calc(100vw - 3rem));
  margin: auto;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 4rem 1.5rem;
  place-content: space-around;
  grid-auto-rows: auto;

  @media (min-width: 75em) {
    grid-template-columns: repeat(auto-fill, minmax(326px, 1fr));
    gap: 4rem 1.5rem;
  }
}

#search {
  &-results {
    position: unset;
    width: 100%;
    height: 100%;
  }

  &-page {
    .card__image {
      aspect-ratio: 1;

      img {
        max-height: unset;
      }
    }

    .hit-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 4rem;
    }

    .quick-search-hits {
      grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
      gap: 1.5rem;
      display: grid;
      place-content: space-around;

      @media (min-width: 75em) {
        grid-template-columns: repeat(auto-fill, minmax(326px, 1fr));
        gap: 4rem 1.5rem;
      }
    }

    .search-page-hits {
      flex: 1 1 auto;
      width: 100%;
    }

    .quick-stats {
      text-decoration: none;
      width: 17rem;
      flex: 0 0 auto;
      padding-bottom: 0;
    }

    .search {
      &-page {
        &-group {
          display: flex;
          gap: 3rem;
          flex-direction: column;

          &-heading {
            font-size: 2.25rem;
            font-weight: 700;
          }

          &-action {
            font-size: 1rem;
            text-decoration: underline;
            cursor: pointer;
            font-weight: 650;
            margin-top: 0.4rem;
          }
        }
      }

      &-hit {
        &-sub {
          margin-top: 0.75rem;
        }
      }
    }

    .all {
      .search-page-group {
        flex-direction: column;
        gap: 1.5rem;

        @media (min-width: 95.625em) {
          flex-direction: row;
        }

        @media (min-width: 95.625em) {
          gap: 3rem;
        }
      }

      .card__image {
        aspect-ratio: 1/0.7;
      }
    }

    .single {
      .quick-search-hits {
        @media (min-width: 75em) {
          grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
        }
      }
    }
  }
}

@keyframes backgroundPulse {
  from {
    background-position: left;
  }

  to {
    background-position: right;
  }
}
