@use '../variables';

.primary-navigation {
  align-items: center;
  background: rgba(white, 0.95);
  inset: 0;
  position: absolute;
  top: 100%;
  height: calc(100vh - 100% - var(--offset, 0));
  overflow: auto;
  display: grid;

  @media (min-width: 1024px) {
    font-size: clamp(0.95em, min(3vh, 1.5vw), 1em);
    gap: var(--g-gap);
    grid-template-columns: var(--g-gap) 1fr min-content;

    &::before {
      content: '';
    }
  }

  a {
    color: inherit;
  }
}

.navigation {
  &.open &-toggle {
    &__icon {
      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }

  &-toggle {
    background: none;
    border: none;
    font: inherit;
    font-size: 1.2em;
    font-weight: 600;
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    gap: 0.5em;
    padding: 0;
    position: relative;
    z-index: 1;

    @media (max-width: 1023px) {
      grid-template-columns: 1fr;

      > span:first-child {
        display: none;
      }
    }

    &__icon {
      align-items: center;
      height: 1.666em;
      width: 1.666em;
      display: grid;

      &::before,
      &::after {
        box-sizing: border-box;
        content: '';
        background: currentColor;
        height: 0.125em;
        width: 100%;
        margin: 0;
        grid-column: 1;
        grid-row: 1;
        transition: transform 0.2s;
      }

      &::before {
        transform: translateY(-0.2em);
      }

      &::after {
        transform: translateY(0.2em);
      }
    }
  }

  &__primary {
    --g-col-size: 12em;

    font-size: 1.2em;
    gap: 0.75em 1em;
    line-height: 1.3;
    list-style-type: none;
    margin: 0;
    margin: auto;
    width: 100%;

    @media (min-width: 1024px) {
      padding: var(--g-gap) 0 0;
      max-height: 100%;
      align-items: start;
      display: grid;
      grid-template-columns: repeat(4, auto);
      grid-template-rows: repeat(calc(var(--span) - 1), auto) minmax(
          max-content,
          1fr
        );
      grid-auto-flow: dense;
    }

    @media (max-width: 1023px) {
      padding: 0 var(--g-gap);
    }

    @media (min-width: 1024px) {
      overflow: auto;

      > li {
        > button {
          display: none;
        }
      }
    }

    @media (max-width: 1023px) {
      display: grid;
      padding: var(--g-gap) var(--g-gap) calc(var(--g-gap) * 2);
      position: relative;
      z-index: 1;

      &::before {
        background: var(--c-off-white);
        box-shadow: inset 0 0 8px rgba(black, 0.1);
        position: relative;
        content: '';
        bottom: 0;
        left: 0;
        right: 0;
        grid-row: 2;
        width: 100%;
        position: absolute;
        height: 100%;
        z-index: -1;

        padding: calc(var(--slope) * 1.5) 0 0;
        transform: skew(0, 3deg);
      }
    }

    ul {
      list-style-type: none;
      padding: 0;

      a {
        text-decoration: none;
      }
    }

    > li {
      > a {
        font-weight: 600;
      }
    }
  }

  &__item {
    & > ul {
      gap: 0.75em;
    }

    &:not(&--single) {
      display: grid;

      @media (min-width: 1024px) {
        gap: inherit;
        grid-row: span var(--span);
      }
    }

    @media (max-width: 1023px) {
      gap: 0 0.75em;
      min-height: 1.55em;

      &:first-child {
        gap: 0.75em;
        padding-bottom: calc(var(--slope) * 1.5);
      }

      &:not(:first-child) {
        > ul > li {
          &:first-child {
            padding-top: 0.5em;
          }
        }
      }

      > ul > li {
        &:last-child {
          padding-bottom: 0.75em;
        }
      }

      &,
      & > ul {
        align-items: start;
      }
    }

    @media (min-width: 1024px) {
      gap: 0.5em;

      &--single {
        grid-column-end: -1;

        &:not(& + &) {
          grid-row-start: 1;
        }
      }

      &:not(&--single),
      &--single--last {
        padding-bottom: 1em;
        padding-bottom: max(1em, 6vh);
      }
    }

    &:not(&--single) {
      display: grid;

      @media (min-width: 1024px) {
        gap: inherit;
        grid-row: span var(--span);
      }
    }

    &:not(&--single),
    &--single--last {
      @media (min-width: 1024px) {
        padding-bottom: 1em;
        padding-bottom: clamp(1em, 3.5vw, 7vh);
      }
    }

    > a {
      grid-area: header;
      font-weight: bold;
      margin-right: auto;

      @media (min-width: 1024px) {
        font-size: clamp(1em, 1.5625vw, 1.25em);
      }
    }

    display: grid;
    grid-template-columns: [menu-start header-start] 1fr [header-end button-start] max-content [button-end menu-end];
    grid-template-rows: [header button] max-content [menu] 1fr;

    > button {
      background: var(--c-goma-grey);
      border: none;
      color: white;
      font: inherit;
      grid-area: button;
      font-size: 0.75em;
      margin: 0;
      padding: 0.5em;

      > div {
        transition: transform 0.2s;

        > svg {
          display: block;
          width: 1em;
        }
      }
    }

    > ul {
      grid-area: menu;
      display: grid;
    }

    @media (max-width: 1023px) {
      a {
        text-decoration: none;
      }

      &:not(:first-child) {
        border-bottom: 1px solid;
      }

      &:first-child {
        > ul {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }

  &__primary__social {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.9em;
    gap: 1.5em;
    gap: min(4vw, 1.5em);
    justify-content: center;
    list-style-type: none;
    margin: auto;
    padding: 3em 1.25em 2em;

    @media (min-width: 1024px) {
      padding: 1.75em 1.25em;
      color: white;
      background: var(--c-goma-grey);
      box-shadow: var(--scrollbar-width) 0 0 0 var(--c-goma-grey);
      display: grid;
      grid-auto-flow: row;

      @media (min-height: 768px) {
        position: relative;

        &::before {
          content: '';
          background: linear-gradient(
            rgba(white, 0),
            var(--c-goma-grey) 20%,
            var(--c-goma-grey) 80%,
            rgba(white, 0)
          );
          position: absolute;
          top: calc(var(--g-gap) * -2);
          bottom: calc(var(--g-gap) * -2);
          width: 1px;
        }
      }
    }

    svg {
      display: block;
      width: 1.5em;
    }
  }
}

.breadcrumbs {
  position: relative;
  z-index: 2;
  ol {
    font-weight: 200;
    list-style-type: none;
    padding: 0;

    &,
    & li {
      display: inline;
    }
  }

  li:not(:first-child) {
    &::before {
      content: '• ';
    }
  }
}
