@use '../../assets/styles/variables';
@use '../../assets/styles/partials/carousel';
@use '../../assets/styles/partials/typography';

.block-linkCarousel {
  @include variables.container;

  overflow: hidden;

  > header {
    @media (min-width: 62em) {
      grid-column: content-start / -4;
    }

    @media (min-width: 75em) {
      grid-column: content-start / span 3;

      h1 {
        grid-column: 1/-1;
        margin-top: 0;
      }
    }

    > div {
      @extend .accent;
    }
  }

  > div {
    grid-column: 2 / -2;

    @media (min-width: 100em) {
      @include variables.container;

      grid-template-columns:
        [content-start]
        repeat(auto-fill, var(--g-col))
        [content-end] calc((100vw - var(--container-width)) / 2);

      mask-image: linear-gradient(
        to right,
        rgba(white, 0),
        white var(--g-col-size)
      );
      -webkit-mask-image: linear-gradient(
        to right,
        rgba(white, 0),
        white var(--g-col-size)
      );
    }

    @media (min-width: 75em) {
      grid-column: 5 / -1;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    width: auto;

    h1 {
      @include variables.font-md;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  .carousel {
    margin: 0;
    grid-column: 1/-1;
    overflow: visible;

    &__slide {
      padding: 0;
    }

    &__container {
      display: grid;
      grid-auto-flow: column;
      grid-gap: calc(var(--g-gap) / 2);
      grid-auto-columns: 100%;
    }

    @media (min-width: 48em) {
      &__container {
        grid-auto-columns: 62%;
        grid-gap: var(--g-gap);
      }
    }

    @media (min-width: 75em) and (max-width: 100em) {
      overflow: hidden;
    }

    @media (min-width: 100em) {
      grid-column-start: 2;
    }
  }

  .card {
    padding: 0 1.5em;

    &__image {
      margin: 0 -1.5em;
      width: auto;
    }
  }
}
