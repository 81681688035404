.creator__ {
  &highlight { 
    text-align: center; 

    &:after { 
      border-bottom: .25em solid currentColor; 
      content: ''; 
      display: block; 
      width: 2rem;
      margin: 1.5rem auto; 
    }
  }

  &highlight p:where(figcaption > p):not(#{&}byline) {
    font-size: .8em;
  }

  &image {
    display:initial;

    img {
      width: 8.071rem;
      border-radius: 4.1rem;
      margin: 0 auto;
    }
  }

  &content {
    & > p:first-of-type { 
      font-size: inherit; 
    }

    .sponsor {
      $ausGovLogo: 'https://cdn.sanity.io/images/m2obzhc2/production/72c78040bdcc88caa6817bc5d68507e7c37b9c2d-295x75.svg';
      svg[data-inject-url="#{$ausGovLogo}"],
      img[src="#{$ausGovLogo}"] { 
        max-width: 12em;
        max-height: 4em;
      }
    }
  }

  &nav{
    display: flex;
    justify-content: space-between;
    align-self: end;
    gap: var(--g-gap);
    padding: var(--g-gap);
    margin-bottom: calc(var(--g-gap) * -1);

    .modal & {
      position: sticky;
      bottom: 0;
      background: rgba(white, 0.85);
      backdrop-filter: blur(.2rem);
      box-shadow: 0 -.1rem .75rem 0 rgba(black, 0.05);

      @container (min-width: 54em) {
        margin: var(--g-gap) calc(var(--g-gap) * -2) calc(var(--g-gap) * -2);
      }
    }

    a {
      gap: .25em;
      font-weight: 600;
      display: flex;
      align-items: baseline;

      svg {
        display: inline-block;
        width: 1em;
        height: auto;
        font-size: .75em;
        vertical-align: baseline;
      }
    }
  }
}