@use '../variables';
@use './typography';

.hero {
  @include variables.angled-container;
  @include variables.container;
  @include variables.reversed;

  align-content: end;
  background: var(--c-mid-grey);
  position: relative;
  display: grid;
  min-height: 90vh;
  min-height: min(90vh, 46rem);
  will-change: opacity;

  > figure {
    grid-column: 1/-1;
    position: absolute;
    overflow: hidden;
    inset: 0;

    :where(picture, img) {
      width: 100%;
      height: 100%;
    }

    :where(img) {
      object-fit: cover;
      object-position: center;
    }

    @media (min-width:1920px) {
      picture {
        --offset: calc(var(--g-col-size) * 4);
        --width: calc(1920px + (var(--offset) / 2));

        margin: auto;
        position: relative;
        z-index: 1;
        max-width: var(--width);

        img {
          mask-image: linear-gradient(to right, transparent, white var(--offset), white calc(100% - var(--offset)), transparent);
        }

        &::before {
          content: '';
          background: inherit;
          inset: 0 calc(var(--gutter) * -1);
          position: absolute;
        }
      }
    }

    // &::after {
    //   content: '';
    //   position: absolute;
    //   inset: 0;
    //   background: linear-gradient(
    //     186.76deg,
    //     rgba(black, 0) 33%,
    //     rgba(black, 0.51974) 63%,
    //     black 88%
    //   );
    //   mix-blend-mode: multiply;
    //   opacity: 0.75;
    // }
  }

  &__wrapper {
    grid-column: 1/-1;
    padding: var(--g-gap) 0;

    @media (min-width: 48em) {
      @include variables.primary-grid;

      grid-column: content;
    }
  }

  &__content {
    display: grid;
    justify-items: start;
    gap: var(--g-gap);
    line-height: 1.3;
    max-width: 40.75em;
    margin: auto 0 auto auto;
    color: white;
    padding: calc(var(--g-gap) * 1.5) calc(var(--g-gap) * 3);
    z-index: 1;
    grid-column: 2/-1;
    position: relative;

    @media (min-width: 48em) {
      background: rgba(black, 0.65);
      grid-column: span 6/-1;
      position: static;
    }

    > * {
      margin: 0;
    }

    h1 {
      @extend .heading-xl;
      line-height: 1.05;
    }

    > p {
      font-size: 1.2em;
      font-weight: 300;
    }

    a {
      color: inherit;
    }

    > figure {
      justify-items: start;
      overflow: visible;
    }

    &::before {
      content: '';
      background: linear-gradient(
        to bottom,
        rgba(black, 0),
        rgba(black, 0.75) 10em,
        black
      );
      position: absolute;
      margin: -6em 0 -3em;
      inset: 0;
      z-index: -1;

      @media (min-width: 48em) {
        margin-bottom: 0;
        opacity: 0.75;
        inset: 0;
        background: linear-gradient(
          186.76deg,
          rgba(black, 0) 33%,
          rgba(black, 0.5) 63%,
          black 88%
        );
      }
    }
  }
}
