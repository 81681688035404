@use '../../assets/styles/variables';
@use '../../assets/styles/partials/typography';

.block-table {
  > div > div > p + p:last-child > a:first-child:last-child {
    @extend .button;
    @extend .button-sm;
  }

  > h1 {
    border-top: 1px solid var(--c-mid-grey);
    border-bottom: 1px solid var(--c-mid-grey);
    margin: 0;
    padding: var(--g-gap) calc(var(--g-gap) * 1.5);

    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    gap: 0.5em;

    img,
    svg {
      width: 1em;
    }
  }

  > div {
    display: grid;
    grid-auto-columns: 1fr;
    gap: 0 calc(var(--g-gap));
    padding: calc(var(--g-gap) / 2) calc(var(--g-gap) * 1.5);

    &:nth-child(even) {
      background: #fafafa;
    }

    > h2 {
      margin-top: 0;
    }

    @media (min-width: 40em) and (max-width: 70em) {
      grid-template-columns: repeat(
        auto-fit,
        minmax(var(--g-col-size), max-content)
      );
      justify-content: space-between;
      padding: calc(var(--g-gap) / 2) calc(var(--g-gap) * 1.5);

      > div:nth-child(2):not(:last-child) {
        grid-column: 1;
      }

      > div:nth-child(3) {
        grid-row: 1 / span 2;
      }

      > div:nth-child(n + 3) {
        grid-column: 2;
      }
    }

    @media (min-width: 70em) {
      grid-auto-flow: column;
      gap: calc(var(--g-gap) / 2) calc(var(--g-gap) * 1.5);
      padding: calc(var(--g-gap) / 2) calc(var(--g-gap) * 1.5);
    }
  }

  & + &,
  & + template + & {
    margin-top: calc(var(--g-gap) * -2);
  }
}
