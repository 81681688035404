﻿.collapsible {
  &__toggle {
    & + & {
      margin-top: 0;
    }

    background: none;
    border: none;
    font: inherit;
    font-size: 0.8em;
    text-decoration: underline;
    margin-top: 0.5em;
    padding: 0;
    display: flex;

    &::after {
      display: block;
      content: '';
      background: url("data:image/svg+xml,%3Csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline y1='-0.487909' x2='4.59036' y2='-0.487909' transform='matrix(0.728848 0.684676 -0.728848 0.684675 0.722656 1.60596)' stroke='%233C3C3C' stroke-width='0.975819'/%3E%3Cline y1='-0.487909' x2='4.59036' y2='-0.487909' transform='matrix(0.728848 -0.684675 0.728848 0.684675 4.07031 4.7489)' stroke='%233C3C3C' stroke-width='0.975819'/%3E%3C/svg%3E%0A");
      margin: auto 0 auto 0.2em;
      width: 8px;
      height: 5px;
    }

    &.open::after {
      transform: rotate(180deg);
    }
  }
}
