@use '../variables';
@use 'typography';
@use 'form';

.calendar {
  margin-top: 0;
  display: grid;
  grid-template-columns: 1fr min-content;

  > * {
    grid-column: span 2;
  }

  > .button {
    margin: var(--g-gap) auto;
  }

  .sticky {
    form {
      font-size: 0.8em;
      gap: 0.5em;
    }
  }

  .sticky & {
    &__filter {
      &--dropdown,
      &--date {
        > {
          label,
          button {
            font-size: 1.5em;
          }
        }
      }
    }

    &__dropdowns {
      gap: 1em;
    }
  }

  > .filters-toggle {
    align-items: center;
    background: none;
    border: none;
    display: grid;
    gap: 0.5em;
    grid-auto-flow: column;
    font-weight: 600;
    margin: 0 auto 0 -0.5em;
    padding: 0 0.5em;
  }

  &__filters {
    align-items: start;
    bottom: 0;
    display: flex;
    left: 0;
    padding: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 11;

    @media (min-width: 64em) {
      z-index: 1;
    }

    .filters-toggle {
      background: none;
      position: absolute;
      top: var(--g-gap);
      right: var(--g-gap);
      z-index: 2;

      svg {
        display: block;
        width: 1.5em;
      }
    }

    > * {
      pointer-events: initial;
    }

    form {
      backdrop-filter: blur(4px);
      background: rgba(white, 0.9);
      display: grid;
      gap: 1.2em;
      grid-column: 1;
      grid-template-rows: auto auto min-content;
      height: 100%;
      justify-content: center;
      text-align: center;
      overflow: auto;
      padding: var(--g-gap) 0;

      @media (min-width: 64em) {
        overflow: visible;
        padding: calc(var(--g-gap) * 0.5) var(--g-gap);
      }
    }

    fieldset {
      background: none;
      border: none;
      padding: var(--g-gap);

      @media (min-width: 64em) {
        padding: 0;
      }
    }

    button {
      border: none;
      color: inherit;
      cursor: pointer;
    }

    @media (min-width: 64em) {
      margin-bottom: var(--g-gap);
      position: sticky;
      top: calc(var(--g-gap) * 0.5);

      form {
        grid-template-columns: initial;
        height: auto;
        margin-left: calc(var(--g-gap) * -1);
        margin-right: auto;
        text-align: left;
      }
    }
  }

  > ul {
    display: grid;
    gap: calc(var(--g-gap) * 2);
    list-style-type: none;
    padding: 0;
  }

  &__dropdowns {
    margin: auto 0 0;

    @media (min-width: 64em) {
      display: flex;
      flex-wrap: wrap;
      gap: 0.25em calc(var(--g-gap) * 1.75);
      margin: 0;
    }
  }

  &__layout-toggle {
    align-self: start;
    align-items: center;
    display: flex;
    grid-area: -1/2 / span 2/-1;
    padding: 0;
    grid-column: 2;
    border: 0;
    gap: 0.25em;

    > div {
      position: relative;
      z-index: 1;
    }

    input {
      appearance: none;
      position: absolute;
      z-index: -1;
    }

    input:not(:checked) ~ label svg {
      opacity: 0.5;
    }

    label {
      display: block;
      padding: 0.25em;
    }

    svg {
      display: block;
      height: 1.5em;
      width: 1.5em;
      transition: opacity 0.2s;
    }
  }

  &__toggles {
    display: flex;
    align-items: start;
    font-size: 0.8em;
    gap: calc(var(--g-gap) / 2);
    justify-content: center;
    line-height: 1;
    margin-top: 0.2em;
    flex-wrap: wrap;
    padding: 0 var(--g-gap);

    @media (min-width: 64em) {
      justify-content: start;
      padding: 0;
    }

    .button {
      padding: 0.75em 1.25em;
      width: auto;

      &:not(:hover) {
        background: var(--c-off-white);
      }

      svg {
        &:not(:first-child) {
          width: 0.625em;
        }
      }
    }
  }

  &__filter {
    position: relative;

    &--dropdown {
      cursor: pointer;

      li {
        padding: 0.6em 0.9em;
        transition: background 0.2s;
      }
    }

    &--dropdown#{&}--dropdown,
    &--date#{&}--date {
      span:last-child {
        margin-top: 0.2em;
        transition: transform 0.2s;
      }

      svg {
        display: block;
        width: 0.5em;
      }
    }

    &--dropdown,
    &--date {
      position: relative;

      > {
        label,
        button {
          align-items: center;
          background: none;
          display: grid;
          font-size: var(--f-s-lg);
          font-weight: 800;
          gap: 0.3em;
          grid-auto-flow: column;
          margin: auto;
          padding: 0;
          text-decoration: underline;
          text-decoration-thickness: 0.06666666666666667em;
          text-underline-offset: 0.1em;
        }
      }

      [role='listbox'] {
        top: 100%;
        left: 0;
        z-index: 3;
        font-size: 1.35em;

        @media (min-width: 64em) {
          position: absolute;
        }
      }

      [role='option'] {
        min-height: 1em;
        white-space: nowrap;
      }

      [role='listbox'],
      fieldset {
        background: var(--c-off-white);
        box-shadow: inset 0px 0px 6px rgba(black, 0.15);
        font-size: 1.35em;
        margin: 0 -0.5em;
        line-height: 1.1;
        padding: 0.5em;
        display: grid;
        min-width: calc(100% + 1em);

        @media (min-width: 64em) {
          background: rgba(white, 0.9);
          box-shadow: none;
        }

        button {
          background: none;
          display: block;
          font-weight: 600;
          margin: auto;
          padding: 0.3em 1em;
          text-align: center;
          transition: background 0.2s;

          &:not(.selected):not(:disabled):hover {
            background: var(--c-goma-grey-disabled);
          }

          &:disabled {
            background: none;
            color: var(--c-goma-grey-disabled);
            cursor: not-allowed;
          }

          @media (min-width: 64em) {
            margin: 0;
            text-align: left;
          }
        }
      }

      button.selected {
        background: var(--c-goma-grey);
        color: white;
      }
    }

    &--date {
      fieldset > div {
        margin: auto;
      }

      button + fieldset {
        border: none;
        z-index: 99;

        @media (min-width: 64em) {
          position: absolute;
          top: 100%;
        }
      }

      .easepick-wrapper {
        display: block;
        margin: 0 -0.75rem;
        padding: 0 1.25rem 0.5rem;
      }
    }

    &--checkboxes {
      border: none;
      display: flex;
      flex: 1 0 100%;
      flex-wrap: wrap;
      padding: 0;
      gap: 0.5em 1.3em;
      justify-content: center;
      margin-bottom: auto;

      @media (min-width: 64em) {
        justify-content: start;
      }

      legend {
        @include variables.sr-only;
      }

      > div {
        @extend .form__input--checkbox;

        label[role='presentation'] {
          border-radius: 2px;
          border-color: currentColor;
          border-width: 1px;
          transition: background 0.2s, border-color 0.2s;

          &::after {
            transform: translateY(-25%) translateX(2%) rotate(-42deg);
          }
        }

        input:disabled ~ label {
          cursor: not-allowed;
        }

        input:disabled ~ label[role='presentation'] {
          background: var(--c-goma-grey-disabled);
          border-color: var(--c-goma-grey-disabled);
        }

        input,
        label[role='presentation'],
        label:not([role='presentation']) {
          font-size: 1em;
        }
      }
    }
  }
}
