.block-instagramFeed {
  place-content: start;
  grid-template-rows: min-content 1fr;
  grid-auto-flow: dense;

  @media (min-width: 95.625em) {
    h1 {
      grid-column: span 4;
    }

    p {
      grid-column: 1 / span 3;
    }
  }

  p a {
    font-weight: 600;
  }

  ul {
    display: grid;
    grid-row: span 2;

    grid-template-columns: repeat(
      auto-fill,
      minmax(calc(var(--g-col-size) * 2), 1fr)
    );
    list-style-type: none;
    padding: 0;
    gap: var(--g-gap);
    margin: 0;

    @media (min-width: 95.625em) {
      grid-column: 5 / content-end;
    }
  }

  li {
    img {
      display: block;
      max-width: 100%;
      aspect-ratio: 1;
      object-fit: cover;
    }

    @supports not (aspect-ratio: 1) {
      a {
        display: block;
        position: relative;

        &::before {
          content: '';
          display: block;
          padding-top: 100%;
          width: 100%;
        }
      }

      img {
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }
}
