﻿@use 'typography';
@use '../variables';

.modal {
  border: none;
  contain: size layout paint;
  container-type: size;
  contain: strict;
  contain-intrinsic-size: calc(100% - var(--g-gap)) calc(100% - var(--g-gap));
  overscroll-behavior: contain;
  scroll-padding: var(--g-gap);
  box-shadow: 0 0 2rem 0 rgba(black, 0.15);

  nav.breadcrumbs { 
    visibility: hidden;
    height: 0;
  }

  &:not(dialog) {
    background: rgba(white, 0.95);
    display: flex;
    place-items: center;
    padding: 1em;
    position: fixed;
    inset: 0;
    margin: 0;
    z-index: 10;
    width: 100vw;

    > div {
      overscroll-behavior: none;
      background: white;
      max-width: 35em;
      line-height: 1.2;
      margin: auto;
      padding: var(--g-gap);
      padding: clamp(var(--g-gap), 8vw, 4em) clamp(var(--g-gap), 10vw, 5em);
      position: relative;
      max-height: 100%;
      overflow: auto;

      > main {
        font-size: var(--f-s-xs);
      }
    }

    h1 {
      @extend .heading-sm !optional;
    }
  }

  &::backdrop {
    background: rgba(white, 0.95);
  }

  @at-root dialog#{&} {
    --gutter: 0;
    --g-col-size: 4.5rem;
    --g-col: minmax(min(var(--g-col-size), 100%), 1fr);
    --container-width: 64rem;

    color: inherit;
    max-width: var(--container-width);
    width: calc(100% - var(--g-gap));
    height: calc(100% - var(--g-gap));
    padding: 0;

    &:not([open]) {
      opacity: 0;
    }

    &[open] {
      display: grid;

      &,
      &::backdrop {
        animation: showDialog 0.3s;
      }
    }

    &[open].closing {
      &,
      &::backdrop {
        animation: hideDialog 0.3s;
      }
    }

    > main {
      display: grid;
      padding:var(--g-gap) 0;
      position: relative;
      z-index:1;
      align-items: start;

      @container (min-width: 54em) {
        padding: calc(var(--g-gap) * 2);
      }

      .hero-image picture {
        height: 55vmin;
      }
    }

    > :where(main, .close-button) {
      grid-column:1;
      grid-row:1;
    }

    .close-button {
      font-size: 1.6em;
    }

    .loader {
      position: absolute;
      inset: 0;
      display: grid;
      place-content: center;
      z-index: 8;
      pointer-events: none;
    }

    &::before {
      content: '';
      pointer-events: none;
      position: absolute;
      inset: 0;
      background: white;
      z-index: 8;
      transition: opacity 0.5s;
    }

    &:not(.loading)::before {
      opacity: 0;
    }

    :where(iframe) {
      max-width: 100%;
    }
  }

  .close-button {
    --margin: calc(var(--g-gap) * 0.4);

    position: sticky;
    top: var(--margin);
    right: var(--margin);
    margin: var(--margin);
    justify-self: end;
    z-index: 9;
    filter: drop-shadow(0 0 0.5em white)
      drop-shadow(0 0 0.1em rgba(255, 255, 255, 0.5));

    @container (min-width: 54em) {
      --margin: var(--g-gap);
    }
  }
}

@keyframes showDialog {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes hideDialog {
  to {
    opacity: 0;
  }
}
