@use '../variables';

.footer {
  @include variables.angled-container-top;
  @include variables.container;
  @include variables.reversed;

  background: var(--c-goma-grey);
  color: var(--c-off-white);
  display: grid;
  gap: var(--g-gap);
  overflow: hidden;

  margin-top: max(calc(var(--g-gap) * 2.5), var(--slope));
  padding-top: max(calc(var(--g-gap) * 2.5), var(--slope));

  a {
    color: inherit;
  }

  &::before {
    content: '';
    height: 1px;
    border-top: 1px solid;
    grid-column: 1/-1;
    order: 0;
  }

  &__form,
  &__social {
    order: -1;
  }

  &__logos {
    font-size: 0.8em;
    margin: auto;
    letter-spacing: 0.01em;
    display: grid;
    gap: 0.5em;
    justify-content: space-between;

    img,
    svg {
      max-height: 2.85em;
      max-width: 27em;
    }

    p {
      text-align: center;
      text-transform: uppercase;
      grid-column: 1/-1;
    }
  }

  &__columns {
    gap: var(--g-gap);

    &::before,
    &::after {
      content: '';
      margin: 0;
      background: currentColor;
    }

    &::after {
      margin-bottom: calc(var(--g-gap) * -1);
    }

    > div {
      max-width: 18em;

      > :first-child {
        margin-top: 0;
      }

      > *:not(h1) {
        font-size: 0.8em;
      }
    }

    ul {
      list-style: none;
      padding: 0;
    }

    li {
      align-items: center;
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: max-content;
      gap: 1em;
      margin: 0.5em 0;

      svg {
        width: 1em;
      }
    }

    p:first-of-type {
      display: grid;
      grid-auto-flow: column;

      span {
        display: grid;
        grid-auto-flow: inherit;
        grid-template-columns: max-content auto;

        &::after {
          content: '';
          border-bottom: 2px dotted;
          margin: auto 0.25em 0.25em;
        }
      }
    }
  }

  &__lower {
    align-items: center;
    background: var(--c-off-white);
    color: var(--c-goma-grey);
    font-size: 0.8em;
    grid-column: 1/-1;
    padding: var(--g-gap) 0;
    position: relative;
    z-index: 1;
    display: grid;
    gap: inherit;
    justify-content: center;
    text-align: center;

    ul {
      justify-content: center;
      flex-wrap: wrap;
      margin: 0;
    }

    li:not(:last-child)::after {
      content: '|';
      padding: 0 0.5em;
    }

    & > :last-child {
      font-weight: 600;
    }
  }

  .emailSignup__message {
    position: relative;
    top: 0;

    span {
      margin: 0;
      width: 100%;
    }
  }

  &__form {
    display: grid;
    gap: var(--g-gap);

    h1 {
      margin: 0;
    }

    input,
    label {
      display: block;
    }

    label,
    input,
    button {
      background: none;
      color: white;
      font: inherit;
      width: 100%;
    }

    input,
    button {
      font-size: 1.5em;
      padding: 0.3em 0.5em;
    }

    input {
      border: 1px solid;
    }

    label {
      display: block;
      margin-bottom: 0.5em;
    }

    button {
      margin-top: 1em;
      font-weight: 600;
      letter-spacing: 0.025em;

      svg {
        width: 0.8em;
      }
    }
  }

  &__social {
    text-align: center;

    ul {
      justify-content: space-between;

      svg {
        display: block;
        height: 1.5em;
      }
    }
  }

  &__lower,
  &__social {
    ul {
      display: flex;
      padding: 0;
      list-style-type: none;
    }
  }

  @media (min-width: 49em) {
    &__social {
      text-align: left;

      ul {
        justify-content: start;
        gap: var(--g-gap);
      }
    }

    &__columns {
      grid-column: 1 / -1;
      display: grid;
      grid-template-columns: 0 [content] 1fr [border-start] 1px [border-end content] 1fr calc(
          (100vw - var(--container-width)) / 2
        );
      grid-template-rows: [border-start] 1px 1fr [content] max-content 1fr [border-end];
      justify-content: space-evenly;

      &::before {
        grid-column: 1/-1;
        grid-row: border-start;
      }

      &::after {
        grid-area: border;
      }

      > div {
        grid-area: content;
        margin: 0 auto auto;

        &:nth-child(even) {
          grid-column: content 2;
        }
      }
    }

    &__lower {
      grid-template-columns: calc((100vw - var(--container-width)) / 2) [content-start] 1fr max-content [content-end] calc(
          (100vw - var(--container-width)) / 2
        );
      justify-content: space-between;
      text-align: left;

      ul {
        justify-content: start;
      }

      > :first-child {
        grid-column: content-start;
      }

      > :last-child {
        grid-column-end: content-end;
      }
    }
  }

  @media (min-width: 67.5em) {
    --column-break: 3;

    @media (min-width: 86.875em) {
      --column-break: 4;
    }

    @media (min-width: 105em) {
      --column-break: 5;
    }

    &::before {
      border: none;
      content: '';
      grid-area: 1/1/1/-1;
    }

    &::after {
      content: '';
      background: currentColor;
      width: 1px;
      grid-area: 1 / auto / -1 / form-end;
      margin: calc(var(--slope) * -1) calc((var(--g-gap) * -1))
        calc(var(--g-gap) * -1) auto;
    }

    grid-auto-flow: dense;
    grid-auto-columns: var(--g-col);
    grid-auto-rows: min-content;
    grid-template-columns:
      calc((100vw - var(--container-width)) / 2)
      [content-start form-start]
      repeat(var(--column-break, 5), var(--g-col))
      [form-end]
      repeat(auto-fill, var(--g-col))
      [content-end]
      calc((100vw - var(--container-width)) / 2);
    grid-template-rows: 0 [form-start] 1fr 1fr [form-end] min-content;

    &__form,
    &__social {
      grid-column-end: form-end;
    }

    &__social {
      grid-row-start: form-end;
    }

    &__logos,
    &__columns {
      grid-column-start: form-end;
    }

    &__columns {
      grid-row: span 2;
    }

    &__form {
      grid-area: form;
      margin-right: clamp(0px, var(--gutter), var(--g-gap));
    }
  }
}
