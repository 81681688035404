@use '../../assets/styles/variables';

.block-videoShowcase {
  @include variables.angled-container;
  @include variables.container;

  background: var(--c-goma-grey);
  color: white;
  z-index: 1;

  iframe {
    aspect-ratio: 16/9;
    display: block;
    height: auto;
    width: 100%;
  }

  &__wrapper {
    @include variables.primary-grid;

    align-items: stretch;
    padding: var(--g-gap) 0 calc(var(--g-gap) * 2);
    gap: 0 var(--g-gap);

    header {
      grid-column: 1/-1;
    }

    @media (min-width: 75em) {
      main {
        grid-column: 1 / -3;
      }

      ul {
        flex-direction: column;
        grid-column: -3 / -1;
      }
    }

    @media (min-width: 100em) {
      main {
        grid-column: 1 / -4;
      }

      ul {
        flex-direction: column;
        grid-column: -4 / -1;
      }
    }

    ul {
      justify-content: space-between;
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      gap: calc(var(--g-gap) / 2);
      padding: 0;
      margin: calc(var(--g-gap) / 2) 0 0;
      height: 100%;

      @media (min-width: 75em) {
        margin: 0;
      }

      li {
        flex: 1 1 11em;
      }

      button {
        background: black;
        border: none;
        border-radius: 0;
        display: grid;
        padding: 0;
        height: 100%;
        width: 100%;
        position: relative;
        grid-template-rows: 1fr min-content 1fr;

        img {
          aspect-ratio: 16/9;
          display: block;
          grid-row: 1/4;
          grid-column: 1;
          object-fit: cover;
          object-position: center;
          height: 100%;
          width: 100%;
        }

        h1 {
          font-size: 1rem;
          font-weight: 300;
          text-align: left;
          margin: 0.8em;
          line-height: 1;
          grid-row: 1;
          grid-column: 1;
          z-index: 1;
        }

        &:not(.selected) {
          img {
            opacity: 0.5;
          }
        }

        &.selected {
          &::before {
            opacity: 1;
          }

          h1,
          &::after {
            opacity: 0;
          }
        }

        &::before,
        &::after,
        h1,
        img {
          transition: opacity 0.2s;
        }

        &::before {
          opacity: 0;
          content: '';
          border: 4px solid white;
          position: absolute;
          pointer-events: none;
          inset: 0;
        }

        &::after {
          background-image: url("data:image/svg+xml,%3Csvg width='51' height='36' viewBox='0 0 51 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M49.4548 5.47703C49.1698 4.41753 48.6114 3.4515 47.8356 2.67569C47.0598 1.89988 46.0938 1.34152 45.0343 1.05652C41.135 0.0117203 25.4996 0.0117188 25.4996 0.0117188C25.4996 0.0117188 9.86414 0.0117203 5.96488 1.05652C4.90538 1.34152 3.93936 1.89988 3.16355 2.67569C2.38774 3.4515 1.82937 4.41753 1.54437 5.47703C0.499571 9.37627 0.499573 17.5117 0.499573 17.5117C0.499573 17.5117 0.499571 25.6472 1.54437 29.5464C1.82937 30.6059 2.38774 31.5719 3.16355 32.3477C3.93936 33.1236 4.90538 33.6819 5.96488 33.9669C9.86414 35.0117 25.4996 35.0117 25.4996 35.0117C25.4996 35.0117 41.135 35.0117 45.0343 33.9669C46.0938 33.6819 47.0598 33.1236 47.8356 32.3477C48.6114 31.5719 49.1698 30.6059 49.4548 29.5464C50.4996 25.6472 50.4996 17.5117 50.4996 17.5117C50.4996 17.5117 50.4996 9.37627 49.4548 5.47703Z' fill='%23D81F1E'/%3E%3Cpath d='M20.4995 25.0118L33.4894 17.512L20.4995 10.0117V25.0118Z' fill='white'/%3E%3C/svg%3E%0A");
          content: '';
          margin: auto;
          width: 2.55em;
          grid-row: 2;
          grid-column: 1;
          height: 1.8em;
          z-index: 1;
        }
      }
    }
  }
}
