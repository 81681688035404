@use '../variables';
@use 'sass:math';

.acknowledgement-of-country {
  @include variables.container;

  background: rgba(white, 0.95);
  padding: var(--g-gap) 0;
  position: fixed;
  inset: 0;
  overscroll-behavior: none;
  text-align: center;
  z-index: 99;

  article {
    font-size: 1.5em;
    margin: auto;
    max-width: 37em;
    pointer-events: none;

    > * {
      pointer-events: initial;
    }
  }

  article,
  button {
    grid-row: 1;
  }

  button {
    grid-column-start: -3;
  }

  button::before,
  button::after {
    grid-column: 1;
    grid-row: 1;
  }
}
