@use '../../assets/styles/variables';
@use '../../assets/styles/partials/typography';

.block-keyPeople {
  display: grid;
  gap: calc(var(--g-gap) * 3) var(--g-gap);
  grid-column: 1/-1;

  .section-header {
    margin-bottom: 0;
  }

  ul {
    display: grid;
    gap: var(--g-gap);
    list-style-type: none;
    margin: var(--g-gap) 0 0;
    padding: 0;

    @media (min-width: 86.25em) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  article {
    display: grid;
    gap: var(--g-gap);

    h1 {
      font-weight: 800;

      span {
        font-weight: 300;
      }
    }

    h2 {
      &::before {
        content: ' | ';
      }
    }

    img {
      display: block;
    }
  }

  > section {
    @include variables.container;

    display: grid;
    gap: var(--g-gap);
    margin-top: calc(var(--g-gap) * 2);

    &:first-child {
      @include variables.angled-container;

      --bottom-offset: 1;

      background: var(--c-off-white);
      padding-bottom: calc(
        var(--slope) + (var(--g-gap) * var(--bottom-offset))
      );
      margin-bottom: calc(var(--g-gap) * var(--bottom-offset) * -1);

      @media (min-width: 81.25em) {
        --bottom-offset: 3;
      }
    }

    > header {
      > * {
        margin: 0;
      }
    }

    @media (min-width: 68.75em) {
      > p {
        grid-column: content / span 6;
      }
    }

    > article {
      align-items: center;
      gap: var(--g-gap);
      order: -1;

      @media (min-width: 81.25em) {
        grid-template-columns: repeat(2, 1fr);
      }

      header {
        @extend .heading-lg;

        > * {
          display: inline;
          margin: 0;
        }
      }

      > div {
        @media (min-width: 81.25em) {
          display: grid;
          grid-template-columns: var(--g-gap) 1fr var(--g-gap);

          > * {
            grid-column: 2/-2;
          }

          > header {
            grid-column-end: -1;
          }
        }

        @media (min-width: 92.8125em) {
          grid-template-columns:
            var(--g-col) repeat(auto-fit, var(--g-gap) var(--g-col))
            var(--g-gap) var(--g-col);

          @media (max-width: 114.3125em) {
            --g-col: minmax(calc(var(--g-col-size) / 2), 1fr);
          }
        }
      }
    }
  }

  section {
    > article {
      margin-bottom: var(--g-gap);
    }

    &:not([data-team='Executive Management Team']) {
      .section-header h2::after {
        content: none;
      }

      article {
        header {
          font-size: 1.2em;

          > * {
            display: inline;
            margin: 0;
          }
        }

        > img {
          aspect-ratio: 1.5;
          object-fit: cover;
        }

        @media (min-width: 36em) {
          grid-auto-flow: column;
          grid-template-columns:
            var(--g-col) [content-start] repeat(auto-fill, var(--g-col))
            [content-end];

          > img {
            aspect-ratio: unset;
            grid-column: 1 / content-start;
          }

          > div {
            grid-column: content;
          }
        }

        @media (min-width: 48.75em) and (max-width: 86.25em),
          (min-width: 95.625em) {
          grid-template-columns:
            repeat(2, var(--g-col)) [content-start] repeat(
              auto-fill,
              var(--g-col)
            )
            [content-end];
        }
      }
    }

    &[data-team='Executive Management Team'] {
      > article {
        order: -1;
      }

      > article header + *,
      ul article h2 {
        @extend .accent;
      }

      ul {
        @media (min-width: 36em) {
          grid-template-columns: repeat(2, 1fr);
        }

        @media (min-width: 70em) {
          grid-template-columns: repeat(4, 1fr);
        }

        article {
          h1 {
            font-size: 1.2em;
          }

          h2 {
            font-weight: 400;
          }
        }
      }
    }
  }
}
