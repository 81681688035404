.block-pullQuote {
  .hero__content {
    padding: calc(var(--g-gap) * 2) calc(var(--g-gap) * 3);
  }

  blockquote {
    margin: 0;
    font-size: 1.5em;
    font-weight: 300;
    position: relative;

    > :first-child {
      margin-top: 0;
    }

    &::before,
    &::after {
      font-weight: 100;
      font-size: 4em;
      line-height: 0.5;
      position: absolute;
    }

    &::before {
      content: '\2018';
      top: 0.2em;
      left: -0.3em;
    }

    &::after {
      content: '\2019';
      bottom: -0.2em;
      right: -0.3em;
    }
  }

  figcaption {
    &::before {
      background: currentColor;
      content: '';
      display: inline-block;
      height: 0.15em;
      margin: auto 0.5em 0.2em 0;
      width: 1em;
    }

    p {
      display: inline-block;
      margin: 0;
    }
  }
}
