.block-callToAction {
  &.even {
    > figure {
      order: -1;
    }
  }

  > div {
    margin: auto;

    .button {
      margin-top: 1em;
    }
  }

  @media (min-width: 62em) {
    align-items: stretch;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: inherit;

    > div {
      order: -1;
      width: 29em;
    }

    picture,
    img {
      height: 100%;
      width: 100%;
    }
  }

  picture {
    width: 100%;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
}
