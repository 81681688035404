@use '../../assets/styles/variables';

.block-timeline {
  --line-col-width: clamp(var(--g-gap), 10vw, var(--g-col-size));

  @include variables.angled-container;

  background: var(--c-off-white);
  grid-column: 1/-1;

  h1 {
    text-align: center;
  }

  ol {
    display: grid;
    padding: 0;
    margin: 0 0 var(--slope);
    list-style-type: none;

    @media (min-width: 1024px) {
      grid-template-columns: 1fr var(--line-col-width) 1fr;
    }

    @media (min-width: 82em) {
      grid-column: 3/-3;
    }

    li {
      display: grid;
      grid-column: 2 / span 2;
      grid-template-columns: var(--line-col-width) 1fr;
      margin: 0.25em 0;
      position: relative;

      &:first-child,
      &:last-child {
        grid-template-rows: repeat(2, 1fr);

        > div {
          grid-row: span 2;
        }
      }

      &:first-child {
        &::before {
          grid-row: 2/3;
          margin-top: 0;
        }
      }

      &:last-child {
        &::before {
          margin-bottom: 0;
        }
      }

      &::before {
        content: '';
        margin: -1em auto -1em 0;
        border: 1px solid;

        @media (min-width: 768px) {
          margin: -1em auto;
        }
      }

      &::after {
        content: '';
        border: 1px dashed;
        left: 0;
        margin: 0;
        position: absolute;
        top: 50%;
        width: var(--line-col-width);

        @media (min-width: 768px) {
          margin: 0 calc(var(--line-col-width) * 0.5);
        }
      }

      @media (min-width: 1024px) {
        &:nth-child(6n + 4),
        &:nth-child(6n + 5),
        &:nth-child(6n + 6) {
          grid-column: 1 / span 2;
          grid-template-columns: 1fr var(--line-col-width);

          &::before {
            order: 1;
          }

          &::after {
            right: 0;
            left: auto;
          }

          h1 {
            order: 2;
          }
        }
      }

      > div {
        background: white;
        position: relative;
        z-index: 1;

        @media (min-width: 768px) {
          align-content: center;
          display: flex;
          justify-content: start;
        }

        > div {
          padding: 0 calc(var(--g-gap) * 1.5);
          width: 100%;
        }
      }

      h1 {
        @include variables.reversed;

        align-items: center;
        background: var(--c-goma-grey);
        color: white;
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0.5em;
        min-width: var(--line-col-width);
      }
    }
  }
}
