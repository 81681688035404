.block-productShowcase {
  .card {
    h1 {
      font-size: var(--f-s-sm);
    }

    &__image {
      border: none;

      img {
        object-fit: contain;
      }
    }
  }
}
