@use '../variables';
@use 'typography';

.mosaic {
  @include variables.container;

  grid-column: 1/-1;
  position: relative;

  > h1 {
    @extend .heading-lg;
  }

  > div {
    @include variables.container;
    gap: calc(var(--g-gap) * 2) var(--g-gap);
  }

  .button {
    margin-left: var(--g-gap);

    & + .button {
      margin-top: calc(var(--g-gap) * 0.75);
    }
  }

  article {
    align-items: end;
    display: grid;
    gap: var(--g-gap);

    > div > div > h1 {
      @extend .heading-lg;
    }

    @media (max-width: 37em) {
      grid-template-columns:
        calc((100vw - var(--container-width)) / 2) [content-start] repeat(
          auto-fill,
          var(--g-col)
        )
        [content-end] calc((100vw - var(--container-width)) / 2);

      > * {
        grid-column: content;
      }

      &,
      > figure {
        grid-column: 1/-1;
      }
    }

    @media (min-width: 37em) {
      grid-template-columns: repeat(
        auto-fill,
        minmax(calc(var(--g-col-size) / 4), 1fr)
      );

      > * {
        grid-column: 1/-1;
      }

      > div {
        display: grid;
        grid-template-columns: inherit;
        gap: inherit;

        > div {
          grid-column: 2/-2;
        }
      }

      @media (max-width: 79.99em) {
        &.even > div {
          grid-column-end: -2;
        }

        &.odd > div {
          grid-column-start: 2;
        }
      }
    }

    @media (min-width: 72em) {
      grid-template-columns: repeat(
        auto-fill,
        minmax(calc(var(--g-col-size) / 2), 1fr)
      );
    }

    @media (min-width: 37em) and (max-width: 90em) {
      grid-template-rows: auto calc(var(--g-col-size) * 1.5) auto;

      > figure {
        grid-row: 1/-2;
      }

      > div {
        grid-row: 2/-1;
      }
    }

    @media (min-width: 80em) and (max-width: 90em) {
      grid-template-rows: auto auto var(--g-col-size);
    }

    @media (min-width: 80em) {
      grid-template-columns: repeat(auto-fill, var(--g-col));

      > * {
        grid-row: 1;
      }

      &.even {
        > figure {
          @include variables.primary-grid;
          grid-template-columns:
            var(--g-col) [content-start] repeat(auto-fill, var(--g-col))
            [content-end] var(--g-col);

          > picture {
            grid-column: 1/-1;
          }

          > figcaption {
            grid-column: 1/-2;
            margin-right: calc(var(--g-gap) * -1);
          }
        }
      }

      > div {
        @include variables.primary-grid;
        grid-template-columns:
          var(--g-col) [content-start] repeat(auto-fill, var(--g-col))
          [content-end] var(--g-col);

        > div {
          grid-column: content;
        }
      }

      &.even > figure,
      &.odd > div {
        grid-column: 1 / span 6;
      }

      &.even > div,
      &.odd > figure {
        grid-column: span 6 / -1;
      }
    }

    @media (min-width: 100em) {
      &.even {
        grid-column-end: -3;
      }

      &.odd {
        grid-column-start: 3;
      }
    }

    > figure img {
      width: 100%;
    }

    > div {
      align-self: end;
      background: white;
      position: relative;
      z-index: 1;

      > div {
        > div {
          @extend .accent;
        }
      }

      @media (min-width: 37em) {
        padding: calc(var(--g-col-size) * 0.75) 0;

        > div {
          > :first-child {
            margin-top: 0;
          }
        }
      }
    }
  }

  @media (min-width: 80em) {
    &:not(.single) {
      > article > div {
        box-shadow: 0.25em 0.25em 0.5em 0 rgba(black, 0.05);
      }

      &::before {
        @include variables.angled-container;

        background: var(--c-off-white);
        content: '';
        padding: 0;
        position: absolute;
        left: 0;
        top: 8.75em;
        bottom: 8.75em;
        right: 0;
        z-index: -1;
      }
    }
  }
}
