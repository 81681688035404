.carousel {
  overflow: hidden;
  position: relative;
  counter-reset: slide;

  figure {
    max-width: calc(100vw - var(--g-gap));
  }

  > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    > li {
      width: auto;
    }
  }

  &__container {
    display: flex;
    grid-gap: calc(var(--g-gap) / 2);

    @media (min-width: 48em) {
      grid-gap: var(--g-gap);
    }

    > * {
      counter-increment: slide;
    }
  }

  &__slide {
    flex: 0 0 auto;
    position: relative;
  }

  &__buttons {
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    display: grid;
    gap: 5px;
    padding: 10px;
    grid-auto-flow: column;

    > * {
      pointer-events: initial;
    }
  }

  &__button {
    background: rgba(white, 0.9);
    border: none;
    color: inherit;
    cursor: pointer;
    font: inherit;
    padding: 1.2em;
    transition: opacity 0.2s, background 0.2s;

    svg {
      display: block;
      width: 1em;
    }

    &:disabled {
      background: rgba(white, 0.9);
      color: inherit;
      opacity: 0.6;
    }

    &:hover {
      background: white;
    }
  }

  &-popup {
    position: fixed;
    z-index: 9999;
    inset: 0;
    display: grid;
    align-items: center;
    background: rgba(white, 0.9);
  }

  &-popup & {
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    pointer-events: none;

    > * {
      pointer-events: initial;
    }

    &__button {
      margin: auto;
      z-index: 1;

      &--prev {
        left: 0;
        order: -1;
      }

      &--next {
        right: 0;
      }
    }

    &__slide {
      max-height: 90vh;
      width: auto;
      max-width: 90vw;

      &,
      & img {
        max-height: max(36em, min(90vh, 720px));
        max-width: 90vw;
      }
    }
  }
}

figure {
  margin: 0;
}
