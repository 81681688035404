@use '../../assets/styles/variables';
@use '../../assets/styles/partials/typography';

.block-flexibleContent {
  > h1 {
    @extend .heading-xl;
  }

  > div {
    @include variables.container;
    gap: calc(var(--g-gap) * 2) var(--g-gap);
  }

  &--columns {
    > header {
      h1 {
        @extend .heading-xl;
      }

      margin-bottom: calc(var(--g-gap) * 2);
    }

    > div {
      --g-col-span: 3;

      display: grid;
      grid-template-columns: repeat(auto-fit, var(--g-col));
    }

    &.max {
      article > div > div > h1 {
        @extend .heading-md;
      }

      img {
        aspect-ratio: 1.1675461741424802;
        object-fit: cover;
      }
    }

    article {
      display: grid;
      grid-template-rows: min-content 1fr;
      grid-column: span var(--g-col-span);
      gap: var(--g-gap);

      .button {
        margin-top: 0.75em;
      }

      > div {
        &,
        > div {
          gap: inherit;
        }
      }

      > div {
        display: grid;

        > div {
          > h1 {
            @extend .heading-lg;

            line-height: 1;
            margin: 0;
          }

          display: grid;
          align-items: start;
          grid-template-rows: repeat(2, min-content) auto;

          > div {
            :first-child {
              margin-top: 0;
            }

            :last-child {
              margin-bottom: 0;
            }
          }

          > a {
            border-bottom: 1px solid var(--c-goma-grey);
            display: flex;
            justify-content: end;
            align-items: center;
            gap: 0.25em;
            text-align: right;
            text-decoration: none;
            font-size: 1.2em;
            font-weight: 600;
            margin-top: auto;

            img {
              width: 1em;
            }
          }
        }
      }
    }
  }
}
