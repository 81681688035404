@use 'sr-only.css';

@use 'variables';
@use 'partials/typography';
@use 'partials/structure';
@use 'partials/form';

@use 'partials/acknowledgementOfCountry';
@use 'partials/header';
@use 'partials/navigation';
@use 'partials/hero';
@use 'partials/calendar';
@use 'partials/footer';
@use 'partials/modal';

@use '../../components/collapsible.scss';

@use '../../exhibitionResource/exhibitionResource.scss';
@use '../../components/blocks/exhibitionResourceFeed.scss';
@use '../../components/blocks/callToAction.scss';
@use '../../components/blocks/expandedText.scss';
@use '../../components/blocks/flexibleContent.scss';
@use '../../components/blocks/keyPeople.scss';
@use '../../components/blocks/imageCarousel.scss';
@use '../../components/blocks/instagramFeed.scss';
@use '../../components/blocks/linkCarousel.scss';
@use '../../components/blocks/productShowcase.scss';
@use '../../components/blocks/promoBanner.scss';
@use '../../components/blocks/pullQuote.scss';
@use '../../components/blocks/storiesShowcase.scss';
@use '../../components/blocks/table.scss';
@use '../../components/blocks/themesShowcase.scss';
@use '../../components/blocks/timeline.scss';
@use '../../components/blocks/venueShowcase.scss';
@use '../../components/blocks/videoShowcase.scss';

:root {
  scroll-behavior: smooth;
}

[x-cloak] {
  display: none !important;
}

.skip-link {
  @include variables.sr-only-focusable;
}

button {
  color: inherit;
  font: inherit;
}

.cta-see-more {
  align-items: center;
  display: inline-grid;
  gap: 0.5em;
  grid-auto-flow: column;
  font-size: 1.2em;
  justify-self: center;

  &#{&} > svg {
    width: 0.75em;
  }
}

figure {
  background: var(--c-background);
  display: grid;
  grid-auto-columns: 1fr;
  padding: 0;
  place-items: end;
  position: relative;

  figcaption:not(.open) {
    > button {
      color: var(--foreground, white);
    }

    > div {
      transform: translateY(calc(100% + 1px));
      visibility: hidden;
    }
  }

  picture + figcaption {
    --foreground: var(--c-foreground, white);
    --background: var(--c-background, lch(33 0 0));
    --light: 1;

    display: grid;
    align-items: end;
    justify-items: inherit;
    max-width: 100%;
    max-width: min(600px, calc(100% - var(--g-gap)));
    overflow:hidden;
    pointer-events: none;
    position: absolute;
    z-index:99;

    > * {
      pointer-events: initial;
    }

    &::before,
    > button {
      position: absolute;
    }

    &::before {
      backdrop-filter: blur(0.5em);
      background: var(--background);
      background: lch(
        from var(--background)
        calc((#{'min(l, 58)'} * var(--light)) + (#{'max(l, 80)'} * (1 - var(--light)))) c h
      );
      content: '';
      height: 7em;
      mask-image: radial-gradient(
        circle at 100% 100%,
        rgba(black, 0.9) 2.25em,
        transparent 5.25em
      );
      mix-blend-mode: luminosity;
      pointer-events: none;
      width: 7em;
      z-index: 1;
    }

    &.reverse {
      --foreground: var(--c-goma-grey);
      --light: 0;
    }

    > button {
      background: none;
      border: none;
      color: var(--c-goma-grey);
      font-size: inherit;
      order: 1;
      padding: 0.75em;
      transition: color 0.2s;
      z-index: 3;

      svg {
        color: inherit;
        display: block;
        width: 1em;
      }
    }

    > div {
      background: rgba(white, 0.9);
      backdrop-filter: blur(3px);
      border:1px solid rgba(60, 60, 60, 0.1);
      border:1px solid color-mix(in srgb, var(--c-goma-grey), transparent 90%);
      font-size: 0.8em;
      line-height: 1.4;
      padding: 2.2em;
      transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1), visibility 0.2s;
      z-index: 2;

      & > :first-child {
        margin-top: 0;
      }

      & > :last-child {
        margin-bottom: 0;
      }

      &:where(:where(.hero, .hero-image) *) {
        padding-bottom: calc(var(--g-gap) * 1.75);
      }
    }
  }
}

.emailSignup {
  border: 3px solid var(--c-goma-grey);
  container-type: inline-size;
  display: grid;
  gap: var(--g-gap);
  grid-column: content;
  padding: calc(var(--g-gap) * 2);
  position: relative;
  text-align: center;

  &__message {
    text-align: center;
    display: flex;

    @media (min-width: 38em) {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
    }

    span {
      display: inline-block;
      font-weight: 600;
      margin: calc(var(--g-gap) * 0.75) auto 0;
      padding: calc(var(--g-gap) / 2) var(--g-gap);
      transition: background 0.2s;
    }

    &:not(.success) span {
      background: var(--c-form-error);
      color: white;
    }

    &.success span {
      background: var(--c-goma-grey);
      color: white;
    }
  }

  @container (min-width: 70em) {
    grid-column: 2/-2;
  }

  > :first-child {
    margin-top: 0;
  }

  > header {
    @include variables.font-md;

    h1 {
      @include variables.font-lg;

      font-weight: 800;
      line-height: 1.05;
      margin: 0 auto var(--g-gap);
      max-width: 20em;
    }

    p {
      font-weight: 300;
      margin: auto;
      max-width: 36em;
    }
  }

  form {
    align-items: end;
    display: flex;
    flex-wrap: wrap;
    gap: calc(var(--g-gap) / 2);
    margin: auto;
    position: relative;
    width: 100%;
    max-width:57.3em;
  }

  &__fields {
    display: grid;
    gap: inherit;
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    justify-content: center;
    flex: 1 1 100%;
  }

  @container (min-width: 30em) {
    form {
      gap: calc(var(--g-gap) / 3) var(--g-gap);
    }
    
    &__fields {
      flex: 5 1 auto;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  & div:not(&__message, &__fields) {
    display: grid;
    padding-top: calc(var(--g-gap) * 1.25);
  }

  button {
    flex: auto;
    margin-top: calc(var(--g-gap) * 1.25);
  }

  label {
    display: block;
    height: calc(var(--g-gap) * 1.25);
    margin-top: calc(var(--g-gap) * -1.25);
    text-align: left;
  }

  input {
    background: none;
    border: 1px solid;
    color: inherit;
    display: block;
    font: inherit;
    font-size: 1.2em;
    padding: 0.66em 1em;
    width: 100%;
  }

  &[style*='background'] {
    @include variables.reversed;

    background-size: cover;
    background-position: center;
    color: white;
    grid-column: 2/-2;
    padding: calc(var(--g-col-size) * 0.75) var(--g-gap)
      calc(var(--g-col-size) * 0.9);
    position: relative;
    overflow: hidden;
    z-index: 1;

    &::before,
    &::after {
      content: '';
      inset: -1em;
      position: absolute;
      z-index: -1;
    }

    &::before {
      filter: blur(0.5em) saturate(0.75);
      background: inherit;
    }

    h1 {
      @include variables.font-xl;
    }

    &::after {
      background: rgba(black, 0.5);
    }
  }

  &[style*='background'] & {
    &__message.success span {
      background: white;
      color: var(--c-goma-grey);
    }
  }
}

.contact-information {
  h1,
  h2 {
    margin: 0;
  }

  h2 {
    font-weight: 300;
  }

  ul {
    list-style-type: none;
    padding: 0;

    &:not(:first-child) {
      margin-top: 0.5em;
    }
  }

  a {
    align-items: center;
    display: inline-grid;
    gap: 0.5em;
    grid-auto-flow: column;
  }
}

.document-list {
  list-style-type: none;
  padding: 0;

  a {
    align-items: center;
    display: inline-grid;
    gap: 0.5em;
    grid-auto-flow: column;
  }

  &--horizontal {
    @media (min-width: 48em) {
      display: flex;
      gap: 0.5em;
    }
  }
}

.contact-information + .document-list {
  margin-top: 0;
}

.operating-hours {
  align-items: center;
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 0.1em 0.5em;

  > :not(svg) {
    grid-column: 2;
  }
}

iframe {
  @at-root :where(&) {
    max-width:100%;
  }

  @at-root :where(&[src*='youtube']) {
    aspect-ratio: 1920/1080;
    height: auto;
  }
}

.logo-block {
  > aside {
    margin-bottom: var(--g-gap);
  }

  &:not(:last-child) {
    > header > h1::after {
      content: none;
    }

    @media (min-width: 72em) {
      grid-column: 4/-4;
    }
  }

  > p + ul {
    margin-top: calc(var(--g-gap) * 2);
  }

  > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: calc(var(--g-gap) * 1.5);

    > li {
      display: flex;
      flex-direction: column;
      flex: 1 1 100%;
    }
  }

  .logo-list {
    margin: auto 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 var(--g-gap);
  }

  > ul + * {
    margin-top: calc(var(--g-gap) * 1.5);
  }
}

.logo-list {
  display: flex;
  flex-wrap: wrap;
  gap: var(--g-gap);
  list-style-type: none;
  
  &:is(ul,ol) {
    padding: 0;
  }

  li {
    margin: auto 0;
  }

  img,
  svg {
    display:block;
    max-height: 2.5em;
    max-width: 8em;
    width: 100%;
  }
}

.page-header {
  @include variables.container;

  gap: calc(var(--g-gap) * 1.5) var(--g-gap);

  @media (max-width: 48em) {
    .hero-image {
      grid-column: -1/1;
    }
  }

  @at-root :where(& > h1) {
    margin: 0;
  }

  @at-root :where(& > .breadcrumbs + h1) {
    margin: calc(var(--g-gap) * -1.5) 0 0;
  }

  @media (max-width: 38em) {
    > h1:first-child:last-child {
      text-align: center;
    }
  }

  > div {
    display: grid;
    gap: inherit;
    grid-template-columns: [content-start] 1px repeat(auto-fill, var(--g-col)) [content-end];

    > section {
      grid-column: content;

      @media (min-width: 67.5em) {
        > p:first-of-type {
          font-size: 1.2em;
          margin-top: 0;
        }
      }

      &:first-child {
        &:last-child {
          grid-column: content/sidebar-end;

          @media (min-width: 70em) {
            &.columns {
              gap: calc((var(--g-col-size) * 2) + var(--g-gap));
            }

            &:not(.columns) {
              grid-column: 2/-2;
            }
          }

          .contact-information {
            display: inline-block;
            padding-right: var(--g-gap);
          }
        }

        &:not(:last-child) {
          align-content: start;
          display: grid;
          gap: inherit;

          & + section > .heading-md:first-child {
            @media (min-width: 67.5em) {
              display: none;
            }
          }

          h1,
          & + section > .heading-md:first-child {
            position: relative;
            padding-bottom: 0.5em;

            &::after {
              content: '';
              position: absolute;
              bottom: 0;
              border-bottom: 1px solid;
              right: 0;
              left: 0;
            }
          }

          > div {
            & > :first-child {
              margin-top: 0;
            }

            & > :last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    @media (min-width: 67.5em) {
      --sidebar-cols: 3;
      grid-template-columns:
        [content-start] repeat(auto-fill, var(--g-col)) [content-end]
        minmax(var(--g-col-size), 1fr)
        [sidebar-start] repeat(
          var(--sidebar-cols),
          minmax(var(--g-col-size), 1fr)
        )
        [sidebar-end];

      > section {
        grid-row: 1;

        &:last-child:not(:first-child) {
          grid-column: content / sidebar-start;
        }

        &:first-child:not(:last-child) {
          font-size: 1.2em;
          grid-column: sidebar;
        }
      }
    }

    @media (min-width: 93.75em) {
      grid-column: 3/-3;
    }

    @media (min-width: 105em) {
      > section:last-child:not(:first-child) {
        grid-column: content;
      }
    }

    @media (min-width: 114.375em) {
      --sidebar-cols: 4;
    }
  }
}

.secondary-content {
  @include variables.container;

  background: var(--c-off-white);
  grid-column: content;
  gap: var(--g-gap);
  padding: var(--g-gap) 0;

  @media (min-width: 74em) {
    grid-template-columns:
      minmax(var(--g-col-size), 1fr) [content-start] repeat(
        auto-fill,
        var(--g-col)
      )
      [content-end] minmax(var(--g-col-size), 1fr);
    padding: var(--g-col-size) 0;
  }

  .section-header {
    margin-bottom: calc(var(--g-gap) * 1.5);
  }
}

.education-resources {
  section > h2 {
    @extend .heading-sm;
  }
  > div {
    display: grid;
    gap: var(--g-gap);
    grid-template-columns: repeat(
      auto-fit,
      minmax(calc(var(--g-col-size) * 2.5), 1fr)
    );
    justify-content: space-between;

    @media (min-width: 74em) {
      gap: calc(var(--g-gap) * 2);
    }
  }
}

.film-trailer {
  @media (min-width: 93.75em) {
    grid-column: 3/-3;
  }
}

.film-details {
  section + section {
    border-top: 1px solid var(--c-mid-grey);
    margin-top: calc(var(--g-gap) * 2);
    padding-top: calc(var(--g-gap) * 2);
  }

  ul {
    gap: var(--g-gap);
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    align-items: end;
    break-inside: avoid;
    display: grid;
    margin-bottom: 1em;
    width: 100%;

    strong {
      display: grid;
      grid-auto-flow: inherit;
      grid-template-columns: max-content auto;
    }
  }

  @media (min-width: 38em) {
    li {
      grid-auto-flow: column;
      grid-template-columns: repeat(2, 1fr);
    }

    strong {
      &::after {
        content: '';
        border-bottom: 2px dotted var(--c-mid-grey);
        margin: auto calc(var(--g-gap) / 4) 0.3em;
      }
    }
  }

  @media (min-width: 62em) {
    ul {
      columns: 2;
      column-width: 25em;
    }

    li {
      gap: var(--g-gap);
    }

    strong {
      &::after {
        margin-right: calc(var(--g-gap) * -0.75);
      }
    }
  }

  @media (min-width: 95.625em) {
    li {
      grid-template-columns: 0.75fr 1fr;
    }
  }
}

.media-links {
  text-align: center;

  ul {
    display: grid;
    gap: var(--g-gap);
    justify-content: center;
    list-style-type: none;
    margin: 0 0 calc(2 * var(--g-gap));
    padding: 0;
    grid-auto-flow: column;

    @media (min-width: 58em) {
      grid-template-columns: repeat(auto-fit, calc(2 * var(--g-col-size)));
    }
  }

  li:not(:first-child) {
    position: relative;

    &::before {
      background: currentColor;
      border-radius: 50%;
      content: '';
      height: 0.25em;
      left: calc(var(--g-gap) * -0.5);
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 0.25em;
    }
  }

  a {
    font-weight: 600;
  }
}

.eventShowcase {
  display: grid;
  grid-template-rows: repeat(2, max-content) [events];

  > ul {
    grid-area: events/1;
  }

  .buttons {
    display: grid;
    gap: var(--g-gap);
    grid-auto-flow: column;
    margin-top: -0.5em;
    align-items: start;
    grid-auto-columns: max-content;
    padding-bottom: calc(var(--g-gap) * 1.5);
    justify-content: space-between;
    max-width: 100%;
    overflow: auto;

    @media (min-width: 48em) {
      gap: calc(var(--g-gap) * 1.75);
      justify-content: start;
    }

    button {
      background: none;
      border: none;
      color: inherit;
      font: inherit;
      padding: 0.5em var(--g-gap);
      margin: auto calc(var(--g-gap) * -1);
      display: grid;
      align-items: start;
      justify-items: center;

      &.active {
        font-weight: bold;
        text-decoration: underline;
      }

      &::before {
        content: attr(title);
        font-weight: bold;
        visibility: hidden;
        opacity: 0;
      }

      span,
      &::before {
        grid-area: 1/1;
      }
    }
  }
}

.visit-snippet {
  @include variables.primary-grid;

  background: var(--c-off-white);
  grid-column: 2/-2;
  padding: var(--g-gap) 0;

  @media (min-width: 75em) {
    padding: calc(var(--g-gap) * 1.5) 0;
  }

  .button {
    justify-content: center;
    margin: 0;
  }

  > div {
    display: grid;
    align-items: start;
    grid-column: content;

    @media (min-width: 75em) {
      column-gap: var(--g-gap);

      grid-template-columns:
        [heading-start] fit-content(20em) [content-start] fit-content(18em)
        [content-end] fit-content(20em) [heading-end];
      grid-template-rows: [heading] auto [content];
      grid-auto-columns: 1fr;
      justify-content: space-between;

      > h1 {
        grid-area: heading;
        justify-content: space-between;
      }

      .button {
        margin: 0 auto;
      }

      > p:first-of-type,
      > .button {
        grid-row: content / span 100;
      }

      > p:first-of-type {
        grid-column-end: content-start;
      }

      > .button {
        grid-column-start: content-end;
      }
    }

    @media (max-width: 87.51em) {
      padding-inline: var(--g-gap);
    }

    @media (min-width: 87.5em) {
      grid-column: 2/-2;
    }

    @media (min-width: 113.75em) {
      grid-column: 3/-3;
    }

    h1 {
      margin-top: 0;
    }

    p {
      margin: 0;

      &:first-of-type {
        line-height: 1.2;
      }

      &:nth-of-type(-n + 2) {
        font-size: 1.2em;
      }

      &:nth-of-type(n + 3) {
        font-size: 0.8em;
      }
    }

    svg {
      width: 1.3em;
    }
  }

  ul {
    display: grid;
    gap: 0.3em;
    list-style-type: none;
    padding-left: 2.5em;
    position: relative;

    svg:first-child:not(:last-child) {
      position: absolute;
      left: 0;
    }

    svg:last-child {
      transform: scale(0.6);
    }
  }
}

.virtual-tour {
  grid-column: content;

  @media (min-width: 70em) {
    grid-column: 3/-3;
  }

  &,
  header {
    display: grid;

    > * {
      grid-area: 1/1;
    }
  }

  iframe,
  header img {
    aspect-ratio: 16/9;
    width: 100%;
    height: auto;
    max-height: 90vh;
  }

  header {
    color: white;
    position: relative;
    text-align: center;
    z-index: 2;
    place-content: center;
    line-height: 1.1;

    img {
      max-height: 90vh;
      height: 100%;
    }

    > * {
      grid-area: 1/1;
    }

    h1,
    h2 {
      @extend .heading-lg;

      font-weight: 300;
    }

    > div {
      margin: 1em;
      filter: drop-shadow(0px 4px 4px rgba(black, 0.25));
    }

    button {
      @extend .heading-md;

      font-weight: 100;
      letter-spacing: 0.01em;

      &::before {
        content: url("data:image/svg+xml,%3Csvg width='113' height='114' viewBox='0 0 113 114' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='56.5' cy='57.4902' r='56.5' fill='white'/%3E%3Cpath d='M41.5 81.7047L83.0677 57.491L41.5 33.2759V81.7047Z' fill='%233C3C3C'/%3E%3C/svg%3E%0A");
        display: block;
        margin-bottom: 1em;
      }

      background: none;
      border: none;
      margin: auto;
      padding: 1em;
    }

    &::before {
      content: '';
      background: rgba(black, 0.25);
      pointer-events: none;
      z-index: -1;
      position: absolute;
      inset: 0;
    }

    img {
      object-fit: cover;
      z-index: -2;
    }
  }
}

.block-instagramFeed {
  h1 {
    @extend .heading-xl;
  }

  p {
    @extend .accent;
    margin-top: 0;
  }
}

.home-hero ~ {
  .block-instagramFeed,
  .block-linkCarousel {
    h1 {
      @extend .heading-lg;
    }
  }
}

.event-details {
  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  &--when {
    .cta-see-more {
      font-size: 0.8em;
    }

    .collapsible ul {
      overflow:auto;
      max-height: 50vh;

      li {
        padding-left:0.4em;
        
        &::marker {
          content: url('data-url:../svgs/li-calendar-sm.svg');
          
          @media (min-width:67.5em) {
            content: url('data-url:../svgs/li-calendar.svg');
          }
        }
      }
    }

    .add-to-calendar {
      display: block;
      width: fit-content;
      margin-top: 0.5em;

      & > button {
        display: flex;
        height: 1.5em;
        border: none;
        padding: 0;
        font-size: .8em;
        text-decoration: underline;
        text-underline-offset: 0.1em;
        text-decoration-thickness: 1px;
        background: none;
        cursor: pointer;
        touch-action: manipulation;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
      
        &::before {
          content: url('data-url:../svgs/calendar-add-sm.svg');
          font-size: 0.7em;
          padding-right: 0.4em;

          @media (min-width:67.5em) {
            content: url('data-url:../svgs/calendar-add.svg');
          }
        }
      }

      #atcb-customTrigger-atcb-btn-custom-host {
        left: calc(100% + 0.75em) !important;
      }
    }
  }

  &--accessibility,
  &--admission {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
  }

  &--accessibility {
    font-size: 0.75em;

    svg {
      max-width: 1.5em;
    }

    ul {
      display: inline-flex;
      gap: 0.75em;
    }

    li {
      align-items: center;
      display: flex;
      gap: 0.5em;
    }
  }

  &--admission {
    > .button {
      display: flex;
      margin-top: 1em;
      width: 100%;

      svg {
        margin: 0.2em 0 0;
      }
    }

    .contact-text {
      font-size: 0.625em;
      text-wrap: wrap;
      text-wrap: pretty;
      text-wrap: balance;
      text-align: center;
    }

    .contact-text + .button {
      margin-top: 0;
    }

    ul {
      li {
        display: flex;
        justify-content: space-between;
        padding: 0.4em 0;
        line-height: 1;

        &::before {
          content: '';
          flex: 1;
          margin: auto 0.5em 0;
          border-top: 1px dotted var(--c-goma-grey-hover);
        }

        h2 {
          margin: 0;
          order: -2;

          small {
            font-size: clamp(14px, 0.6em, 1rem);
            font-weight: 600;
          }
        }

        button {
          align-self: start;
          background: none;
          border: none;
          font: inherit;
          order: -1;
          padding: 0;
          margin-left: 0.25em;
          margin-right: -0.25em;

          svg {
            display: block;
            height: 0.625em;
            width: 0.625em;
          }
        }
      }
    }
  }
}
