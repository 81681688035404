@use '../../assets/styles/variables';

.block-expandedText {
  @include variables.container;

  &:not(&--grid) {
    @media (min-width: 70em) {
      > div > div > div:first-child:last-child {
        columns: 2;
        gap: var(--g-gap);

        > p {
          margin-top: 0;
        }
      }
    }
  }

  @media (min-width: 37em) {
    &:not(& + &) {
      @include variables.angled-container;

      background: var(--c-off-white);
    }
  }

  & + & {
    padding-top: 0;
  }

  > div {
    @include variables.primary-grid;

    gap: var(--g-gap);
    padding: calc(var(--g-gap) * 2) 0 calc(var(--g-gap) * 4);

    @media (min-width: 74em) {
      grid-column: 3/-3;
    }

    div {
      break-inside:avoid;
    }
  }

  &--grid {
    > div > div {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 1.2em;
      }

      @media (min-width: 48em) and (max-width: 49em) {
        grid-template-columns: repeat(
          auto-fill,
          minmax(calc(var(--g-col-size) * 2), 1fr)
        );
        gap: var(--g-gap);
      }

      @media (min-width: 49em) {
        display: grid;
        gap: calc(var(--g-gap) * 2);
        grid-template-columns: repeat(
          auto-fill,
          minmax(calc(var(--g-col-size) * 3), 1fr)
        );
      }
    }

    &--2 {
      > div > div {
        @media (min-width: 48em) {
          grid-template-columns: repeat(
            auto-fill,
            minmax(calc(var(--g-col-size) * 4), 1fr)
          );
        }
      }
    }
  }
}
