@use '../../assets/styles/variables';

.block-exhibitionResourceFeed {
  @include variables.angled-container;
  @include variables.container;
  @include variables.reversed;

  background: rgb(236, 232, 25);
  color: black;
  z-index: 1;

  &__wrapper {
    padding: calc(var(--g-gap) * 2) 0;

    &>.button {
      margin: var(--g-gap) auto;
      display: block;
    }
  }

  .section-header { display: block; }

  .cta-see-more { display: none }

  .exhibitionResourceFeed {    
    &__search_bar {
      display: flex;
      justify-content: space-between;
      margin-top: var(--g-gap);
      row-gap: 1em;
      flex-wrap: wrap;
    }

    &__search {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1em;
      border-bottom: 2px solid black;
      min-width: 40%;
  
      input {
        width: 100%;
        font-size: 1.2rem;
        font-weight: 650;
        border: none;
        background-color: transparent;  
        &:focus { outline: none; }
        &::placeholder { color: black; }
      }

      button {
        background: none;
        border: none;
        cursor: pointer;
        display: grid;
        padding: 0;
        svg { width: 1.5em; }
      }
    }

    &__filter{
      display: inline-flex;
  
      .button.selected {
        color: var(--_button-bg);
        background: black;
        border-color: black;
      }
    }
  }

  .card {
    h1 { font-size: var(--f-s-sm); }
    &__image { border: none; }

    &.hideCreator { 
      display: none;
      color: var(--c-mid-grey);
    }
  }
}
