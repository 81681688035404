html {
  color: var(--c-goma-grey);
  font-family: Flama, sans-serif;
  font-size: var(--f-s-root);
  font-weight: 400;
  line-height: 1.5;
}

::selection {
  color: var(--selection-fg);
  background: var(--selection-bg);
}

mark, :where([id^="cite-"], [id^="cite-"]):target {
  background-color: lightblue;
}

a {
  color: inherit;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.1em;
}

:where(ul, ol) {
  padding-inline-start: 1em;
}

li {
  &::marker {
    font-size: 0.7em;
    margin-top: 0.25em;
  }
}

:where(h1, h2, h3, h4, h5, h6) {
  font-size: 1em;
  margin-block: 0.75em;
}

small {
  display: inline-block;
}

[class*='heading'] {
  line-height: 1.1;
}

.uppercase {
  text-transform: uppercase;
}

.truncate {
  -webkit-line-clamp: var(--lines, 4);
  line-clamp: var(--lines, 4);
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.heading {
  &-xl,
  &-lg,
  &-md {
    line-height: 1.1;
    font-weight: 800;

    span {
      display: inline-block;
      font-weight: 300;
    }
  }

  &-xl {
    font-size: var(--f-s-xl);
  }

  &-lg {
    font-size: var(--f-s-lg);
  }

  &-md {
    font-size: var(--f-s-md);
  }

  &-sm {
    font-size: var(--f-s-sm);
    font-weight: 700;
    line-height: 1.4;
  }

  &-xs {
    font-size: var(--f-s-xs);
    font-weight: 400;
  }
}

.content-wrapper {
  svg {
    display: inline-block;
    height: auto;
    vertical-align: middle;
  }

  a:not(.logo),
  button {
    svg {
      @at-root :where(&) {
        width: 1em;
      }
    }
  }
}

.button {
  --_button-bg: var(--button-bg, var(--c-goma-grey));
  --_button-fg: var(--button-fg, white);

  align-items: center;
  background: none;
  border: 0.125em solid;
  color: inherit;
  display: inline-grid;
  font-size: 1.2em;
  font-weight: 600;
  gap: 0.5em;
  grid-auto-flow: column;
  max-width: 100%;
  min-width: 10em;
  padding: 0.6em 2.25em;
  text-align: center;
  text-decoration: none;
  justify-content: center;
  transition: background 0.2s, border-color 0.2s, color 0.2s;

  &[disabled] {
    color: var(--c-goma-grey-hover);
  }

  &:hover:not([disabled]) {
    background: var(--_button-bg);
    border-color: var(--_button-bg);
    color: var(--_button-fg);
  }

  @media (max-width: 37em) {
    display: grid;
    width: 100%;
  }

  &:not(#{&}-sm) {
    svg {
      margin-right: -0.5em;
    }
  }

  svg {
    font-size: 0.9em;
    height: 1em;
    margin: auto;
    width: 1em;
  }

  &-sm {
    font-size: 1em;
    min-width: 0;
    padding: 0.5em 1em;
  }
}

.accent {
  margin-top: 1em;
  padding-left: var(--g-gap);

  &::before {
    content: '';
    width: calc(var(--g-gap) * 0.6);
    border-top: 0.2em solid;
    display: inline-block;
    margin-top: 0.7em;
    margin-left: calc(var(--g-gap) * -1);
    position: absolute;
  }
}
