@use '../variables';

* {
  box-sizing: border-box;
}

html {
  background: white;
  height: 100%;
}

body {
  display: grid;
  min-height: 100%;
  margin: 0;
}

img {
  @at-root :where(&) {
    height: auto;
    object-fit: contain;
    max-width: 100%;
  }

  @at-root :where(.content-image &) {
    display: block;
    max-height: 90vh;
    max-height: min(90cqh, var(--height));
    width: 100%;
  }
}

:where(svg) {
  fill: currentColor;
  stroke: none;
}

.grid {
  @include variables.primary-grid;

  @at-root :where(& > *) {
    grid-column: 1 / content-end;

    &:first-child,
    &.home-hero {
      margin-top: 0;
    }
  }
}

.container {
  @include variables.container;
}

.wrapper {
  container-type: inline-size;
  display: flex;
  flex-direction: column;
  margin: 0;
  max-width: 100%;
}

.content-wrapper {
  flex: 1 0 auto;

  @media (min-width: 48em) {
    padding: var(--g-gap) 0 0;
  }
}

.hero-image {
  @include variables.angled-container-bottom;

  display: grid;
  padding: 0;
  will-change: opacity;

  figure,
  video {
    grid-area: 1/1;
  }

  &:not(.home-hero) {
    picture,
    video {
      height: 75vmin;
      max-height: 34rem;
      height: clamp(10em, min(37vmax, 75vmin), 34rem);
      height: clamp(10em, min(37cqmax, 75cqmin), 34rem);
      width: 100%;
    }
  }

  figure {
    position: relative;
    z-index: 1;
    + video {
      z-index: 2;
    }
  }

  img {
    display: block;
    height: 100%;
    width: 100%;
  }

  img,
  video {
    object-fit: cover;
    object-position: center;
  }
}

.home-hero {
  grid-template-columns: 0 [content-start] 1fr [content-end] 0;
  grid-template-rows: auto [content-start] min-content [content-end] calc(var(--slope));
  gap: var(--g-gap);
  display: grid;
  color: var(--fg);
  background: var(--bg);
  grid-column: edge;
  min-height: 75vh;
  min-height: clamp(10em, min(75vmax, 75vh), calc(var(--container-width) * 0.6));
  margin: 0;
  margin-bottom: calc(var(--g-gap) / -2);

  @media (min-width: 48em) {
    grid-column: content;
    margin-bottom: 0;
  }

  @media (min-width: 62em) {
    grid-template-columns: 0[content-start] repeat(auto-fill, var(--g-col)) [content-end]0;
  }

  > * {
    grid-column: 1/-1;
    z-index: 1;
  }

  video,
  picture {
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  video, img {
    object-fit: cover;
  }

  > *:first-child {
    grid-row: 1/4;
    display: grid;
    position: relative;

    > * {
      grid-area: 1/1;
      position: absolute;
      height: 100%;
      margin: 0;
    }
  }

  &::after {
    grid-column: 1/-1;
    pointer-events: none;
    grid-row: 2/4;
    content: '';
    background: var(--bg);
    mask-image: linear-gradient(to top, black 20%, transparent);
    -webkit-mask-image: linear-gradient(to top, black 20%, transparent);
    margin: calc(var(--slope) * -1.5) 0 0;
    position: relative;
    z-index: 1;
  }

  > div {
    align-self: end;
    grid-area: content;
    z-index: 2;
    position: relative;
    display: grid;
    align-items: end;
    gap: calc(var(--g-gap) * 0.5);
    grid-template-columns: 1fr fit-content(90%);

    @media (min-width: 38em) {
      grid-template-columns: max-content 1fr max-content;
    }

    @media (min-width: 62em) {
      grid-column-start: span 4;
    }

    @media (min-width: 91.875em) {
      grid-column-start: span 5;
    }

    h1 {
      grid-column: 1 / -1;
      text-shadow: 0px 0px 4px rgba(black, 0.35);
      margin: 0;

      span {
        display: block;
      }
    }

    p {
      grid-column: 1/-1;
      margin-top: 0;
      max-width: 32em;
    }

    &::after {
      border-top: 1px solid;
      content: '';
      margin-bottom: 0.25em;
    }

    h2 {
      font-weight: 300;
      grid-column: 1/-1;
      margin: 0 0 -0.125em;

      @media (min-width: 38em) {
        grid-column: 1;
      }
    }

    a {
      order: 2;
    }
  }
}

.home-hero ~ .emailSignup {
  grid-column: content;
}

header.grid {
  gap: var(--g-gap);

  h1 {
    margin-top: 0;
  }

  picture {
    @include variables.angled-container-bottom;
    padding: 0;

    img {
      display: block;
    }
  }
}

@media (min-width: 70em) {
  .columns {
    columns: 2;

    > p {
      margin-top: 0;
    }

    > * {
      break-inside: avoid;

      &:not(.contact-information) {
        width: 100%;
      }
    }

    @at-root header & {
      grid-column: 2/-2;
      column-rule: 1px solid var(--c-goma-grey);
      gap: calc(20% + var(--g-gap));
    }
  }
}

.card {
  align-content: start;
  display: grid;
  grid-column: auto;
  gap: calc(var(--g-gap) * 0.5);

  & > a:not(&__cta) {
    margin-bottom: calc(var(--g-gap) * 0.5);
    position: relative;
  }

  &__info {
    background: var(--c-goma-grey);
    bottom: 0;
    color: white;
    font-size: var(--f-s-xs);
    font-weight: 700;
    padding: 0.4em 1.75em;
    position: absolute;
    right: 0;
    text-transform: uppercase;
    z-index: 1;
  }

  &__image {
    width: 100%;
    display: flex;

    picture {
      place-self: stretch;
    }

    img {
      display: block;
      object-fit: contain;
      object-position: center;
      height: 100%;
      width: 100%;
    }

    &--cover {
      img {
        object-fit: cover;
      }
    }
  }

  &__summary {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  &__details {
    line-height: 1.3;
    list-style-type: none;
    margin: 0;

    h2 {
      font-weight: 400;
    }

    .button {
      margin-top: 1em;
    }
  }

  &__when {
    font-weight: 400;
  }

  &__where {
    font-weight: 600;
  }

  &__admission {
    font-weight: 700;

    span {
      background: var(--c-off-white);
      display: inline-block;
      margin-top: 1em;
      padding: 0.5em 1em;
    }
  }

  img {
    display: block;
    object-fit: cover;
    width: 100%;
  }

  h1 {
    font-weight: 600;
  }

  h1,
  h2,
  h3 {
    line-height: 1.1;
    margin: 0;

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  @at-root .past & {
    opacity: 0.75;

    header h1 {
      font-size: var(--f-s-sm);
    }

    img {
      aspect-ratio: 1.45;
    }
  }

  &--list {
    @include variables.primary-grid;

    gap: var(--g-gap);

    img {
      aspect-ratio: 1.75/1;
    }

    > * {
      grid-column: auto;
    }

    grid-column: 1/-1;
    grid-auto-flow: row;

    > a {
      grid-column: span 4;
      grid-row: span 2;
    }

    > {
      h1,
      ul {
        grid-column: 5/-1;
      }

      h1 {
        margin: auto 0 0;
      }

      ul {
        margin: 0 0 auto;
      }
    }
  }

  &__cta {
    border-bottom: 1px solid var(--c-goma-grey);
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 0.25em;
    font-weight: 600;
    margin: auto 0 0;
    text-align: right;
    text-decoration: none;
    width: 100%;

    img {
      width: 1em;
    }
  }
}

.card-grid {
  --column-count: 4;

  gap: calc(var(--g-gap) * 2) var(--g-gap);
  grid-template-columns: [content-start] repeat(
    auto-fill,
    minmax(calc(var(--g-col-size) * 2), 1fr)
  ) [content-end];

  @media (min-width: 49em) {
    grid-template-columns: [content-start] repeat(var(--column-count), 1fr) [content-end];
  }

  list-style-type: none;
  margin: 0;
  
  &:where(ul, ol) {
    padding: 0;
  }

  .card {
    margin: 0;
  }

  &[style='--column-count:2'] {
    h1 {
      font-size: var(--f-s-lg);
      font-weight: 800;
    }
  }

  &:not([style='--column-count:2']):not(.past &) {
    img {
      aspect-ratio: 1;
    }
  }
}

.section-header {
  display: grid;
  margin-bottom: calc(var(--g-gap) * 1.5);
  align-items: end;
  gap: calc(var(--g-gap) / 2);
  text-align: center;

  @media (min-width: 38em) {
    grid-auto-flow: column;
    grid-template-columns: 1fr;
    grid-auto-columns: fit-content(100%);
    text-align: left;
  }

  [class*='heading'] {
    align-items: center;
    display: grid;
    gap: inherit;
    grid-auto-flow: column;
    grid-template-columns: 1fr fit-content(100%) 1fr;
    margin: 0;
    order: -1;

    &::before,
    &::after {
      content: '';
      border-bottom: 1px solid;
      margin: 0.12em 0 0 0;
      opacity: 0.9;
      width: calc(100% + var(--g-gap));
    }

    &::before {
      justify-self: end;
    }

    @media (min-width: 38em) {
      align-items: end;
      grid-template-columns: fit-content(100%) 1fr;

      &::before,
      &::after {
        margin: 0 0 0.125em;
      }

      &::before {
        content: none;
      }

      &::after {
        width: 100%;
      }
    }
  }

  > a {
    color: inherit;
    font-weight: 800;
    text-decoration-thickness: 1px;
    text-underline-offset: 0.05em;

    svg {
      display: inline-block;
    }
  }
}

.block {
  &-pullQuote,
  &-promoBanner,
  &-videoShowcase,
  &-expandedText {
    & + & {
      margin-top: calc(((var(--g-gap) * 2) + var(--slope)) * -1 + 2px);
    }

    &:last-child {
      margin-bottom: calc(var(--slope) * -2 + 2px);
    }
  }
}

.close-button {
  align-items: center;
  background: none;
  border: none;
  display: grid;
  font-size: 1.2em;
  height: 1.666em;
  margin: 0 0 auto auto;
  padding: 0;
  position: relative;
  width: 1.666em;

  &::before,
  &::after {
    box-sizing: border-box;
    content: '';
    background: currentColor;
    height: 0.125em;
    width: 100%;
    margin: 0;
    grid-column: 1;
    grid-row: 1;
    transition: transform 0.2s;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.error_404 {
  text-align: center;

  p {
    font-size: 1.2em;
    margin: auto;
    max-width: 25em;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: var(--g-gap);
    justify-content: center;
    margin: var(--g-gap);
    list-style-type: none;

    a {
      background: var(--c-off-white);
      font-size: 1.2em;
      padding: 0.3em 0.75em;
      text-decoration: none;
    }
  }
}
