﻿@use '../../assets/styles/variables';

.block-themesShowcase {
  --gutter: 0;

  @include variables.container;

  row-gap: 0;

  @media (min-width: 64em) {
    grid-template-columns:
      [edge-start] var(--gutter) [content-start header-start] repeat(
        auto-fill,
        [col] var(--g-col)
      )
      [header-end] repeat(2, var(--g-col)) [content-end]
      var(--gutter) [edge-end];
  }

  > header {
    text-align: center;

    > h1 {
      @media (max-width: 64em) {
        &::after {
          content: '';
          width: 0.75em;
          border-top: 0.1em solid;
          display: block;
          margin: 0.5em auto 0;
        }
      }
    }

    @media (min-width: 64em) {
      text-align: left;
      position: relative;
      margin-right: auto;
      grid-column: header;
      z-index: 1;

      > h1 {
        filter: drop-shadow(0 0 0.1em white) drop-shadow(0 0 1px white);
        margin-bottom: calc(var(--g-gap) * -0.6);
      }

      &::before {
        background: white;
        bottom: 0;
        content: '';
        height: 1px;
        left: 0;
        margin: auto -1em -1px;
        position: absolute;
        right: 0;
        z-index: -1;
      }
    }

    @media (min-width: 64em) and (max-width: 90em) {
      margin-left: var(--g-gap);
    }

    @media (min-width: 90em) {
      grid-column-start: 3;
    }
  }

  &__wrapper {
    @include variables.primary-grid;
    row-gap: 0;

    align-items: start;
    height: 75vh;

    @media (min-width: 64em) {
      grid-template-columns: [content-start] repeat(auto-fill, var(--g-col)) [content-end] repeat(
          2,
          var(--g-col)
        );

      > * {
        grid-row: 1;
      }
    }

    > .carousel {
      height: 100%;
    }

    > .carousel__buttons {
      gap: 1px;
      padding: 0;
      position: relative;
      display: grid;
      margin-bottom: calc(clamp(2.625rem, 9.25vw, 2.9em) * -0.5);
      grid-template-columns: [button] min-content 1fr [heading] auto 1fr [button] min-content;
      z-index: 1;

      &::before {
        content: '';
        height: 3px;
        background: white;
        grid-area: heading;
        grid-row: 1;
        margin: auto -1em;
      }

      > * {
        grid-row: 1;
      }

      h1 {
        filter: drop-shadow(0 0 0.1em white) drop-shadow(0 0 1px white);
        grid-column: heading;
        margin: 0 auto;
      }

      @media (min-width: 30em) {
        grid-template-columns: repeat(2, clamp(2.625rem, 9.25vw, 3.5em)) 1fr [heading] auto 1fr repeat(
            2,
            [button] clamp(2.625rem, 9.25vw, 3.5em)
          );
      }

      button {
        flex: 0 0 auto;
        background: var(--c-goma-grey);
        color: white;
        display: grid;
        padding: 0;
        height: clamp(2.625rem, 9.25vw, 2.9em);
        width: clamp(2.625rem, 9.25vw, 3.5em);
        place-items: center;

        &:first-of-type {
          grid-column: button 1;
        }

        &:last-of-type {
          grid-column: button 2;
        }
      }

      @media (min-width: 64em) {
        display: none;
      }
    }
  }

  &__thumbnails {
    background: var(--c-goma-grey);
    color: white;
    grid-column: content-end / -1;
    margin: 0;
    width: 100%;
    counter-reset: thumbnail;

    .carousel__container {
      gap: var(--gap);
      flex-direction: column;
    }

    button {
      background: none;
      border: none;
      color: inherit;
      counter-increment: thumbnail;
      font-weight: 600;
      display: grid;
      gap: 0.5em;
      grid-auto-flow: row;
      padding: calc(var(--g-gap) / 2);
      text-align: left;
      display: none;
      transition: opacity 0.2s;

      @media (min-width: 64em) {
        display: grid;
      }

      img {
        width: 100%;
      }

      img,
      &::before,
      &::after {
        grid-column: 1;
        grid-row: 1;
      }

      &::before {
        content: counter(thumbnail) '.';
        font-size: 0.875em;
        line-height: 1;
        padding: 0.5em 0.75em;
        z-index: 1;
        margin: auto auto -1px -1px;
        background: var(--c-goma-grey);
      }

      &::after {
        content: '';
        border: 3px solid;
        border-color: transparent;
        margin: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        transition: border-color 0.2s;
      }

      &.is-selected:after {
        border-color: white;
      }

      &:not(.is-selected) {
        opacity: 0.5;
      }
    }
  }

  &__content {
    border: 1px solid #d5dadf;

    @media (min-width: 64em) {
      margin-right: calc(var(--g-gap) * -1);
    }

    .carousel__container {
      height: 100%;
    }
  }

  &__item {
    mask-image: linear-gradient(
        to bottom,
        rgba(black, 0),
        black var(--g-gap),
        black calc(100% - var(--g-gap)),
        rgba(black, 0)
      ),
      linear-gradient(to left, black, black 17px, rgba(black, 0) 17px);

    padding: calc(var(--g-gap) * 2) var(--g-gap);
    height: inherit;
    overflow: auto;
    flex: 1 0 100%;

    @media (min-width: 64em) {
      &::before {
        content: none;
      }

      scrollbar-gutter: stable;
      display: grid;
      gap: var(--g-gap);
      grid-template-columns: repeat(auto-fill, var(--g-col));
    }

    @media (min-width: 113em) {
      padding: var(--g-col-size) 0;
    }

    &__title {
      font-size: var(--f-s-lg);
    }
  }

  &__inner {
    grid-column-start: 1;
    grid-column-end: -1;

    @media (min-width: 90em) {
      align-items: start;
      display: grid;
      gap: 0 var(--g-gap);
      grid-template-columns: repeat(auto-fill, var(--g-col));
    }

    @media (min-width: 112em) {
      grid-column-start: 2;
      padding-left: 0;
    }

    > h1:first-child {
      display: none;
      font-size: var(--f-s-lg);
      margin-top: 0;

      @media (min-width: 64em) {
        display: block;
      }
    }
  }

  &__images {
    margin: 0 auto;
    max-width: 60vh;

    picture {
      max-height: 75vh;
    }
  }

  @media (min-width: 64em) {
    &__inner > * {
      max-width: 35rem;
      width: 100%;
    }

    &__inner > *:not(&__images) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (min-width: 90em) {
    &__inner > *:not(&__images) {
      grid-column: span 5;
    }

    &__images {
      grid-column: 1/-6;
      grid-row: 1 / span 100;
      order: -1;
      position: sticky;
      top: 0;
    }
  }

  @media (min-width: 100em) {
    &__inner > *:not(&__images) {
      grid-column: span 6;
    }

    &__images {
      grid-column: 1/-7;
    }
  }

  .carousel > ul > li {
    max-width: 100%;
  }
}
