@use '../variables';

.form {
  @include variables.container;

  &__wrapper {
    @include variables.primary-grid;

    background: var(--c-off-white);
    grid-column: 1 / end;

    @media (min-width: 70rem) {
      grid-column: content;
    }
  }

  &__inner {
    @include variables.primary-grid;

    gap: var(--g-gap);
    margin: var(--g-gap) 0;
    padding: var(--g-gap);

    @media (min-width: 70rem) {
      grid-column: 2/-2;
      padding: var(--g-gap) 0;
    }
  }

  &__privacy {
    text-align: center;

    a {
      display:inline-block;
    }

    > :first-child {
      margin-top:0;
    }
    
    > :last-child {
      margin-bottom:0;
    }
  }

  iframe {
    border:none;
    width:100%;
  }

  label,
  textarea,
  select,
  input {
    font: inherit;
  }

  :where(input, textarea, select, option) {
    color: var(--c-goma-grey);
  }

  select:where(:invalid, :has(option[value=""]:checked)) {
    color: var(--c-mid-grey);
  }

  option:where(:disabled, [value=""]) {
    color: GrayText;
  }

  textarea,
  select,
  input:not([type='radio']):not([type='checkbox']) {
    border: 1px solid var(--c-goma-grey-disabled);
    padding: 0.75em 1em;
    font-size: 1.2em;
  }

  label,
  textarea,
  select,
  input:not([type='radio']):not([type='checkbox']) {
    display: block;
    width: 100%;
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: 2px solid #00a699;
  }

  button:not(:disabled):hover {
    cursor: pointer;
  }

  form.processing button:disabled:hover {
    cursor: wait;
  }

  form.submitted > * {
    display: none !important;
  }

  form.submitted > .form__messages {
    display: block !important;
  }

  .required-flag {
    color: var(--c-form-error);
  }

  label,
  legend {
    margin: 0.5em 0;
  }

  textarea {
    height: 9.5em;
  }

  fieldset {
    border: none;
    margin: 0;
    padding: 0;
  }

  .form__input.hidden {
    opacity: 0;
  }

  input.hidden {
    display: none !important;
  }

  &__footer {
    display: flex;
    gap: var(--g-gap);
    margin-top: var(--g-gap);
    justify-content: end;

    > :not(button[type="submit"]) {
      order:-1;
    }
  }

  &__messages {
    background: var(--c-goma-grey);
    color: white;
    
    &:not(:empty) {
      padding: var(--g-gap);
    }
    
    &:first-child {
      margin-top:0;
    }
    
    &:last-child {
      margin-bottom:0;
    }
  }

  &__fieldset {
    display: grid;
    gap: var(--g-gap);
    grid-template-columns: repeat(auto-fit, minmax(16em, 1fr));
  }

  &__input {
    &--checkbox,
    &--radio {
      align-items: center;
      display: grid;
      grid-template-columns: max-content auto;
      position: relative;

      input,
      label[role='presentation'] {
        background: var(--c-white);
        border: 1px solid var(--c-goma-grey-disabled);
        font-size: 1.2em;
        grid-column: 1;
        grid-row: 1;
        height: 1em;
        margin: 0;
        width: 1em;
      }

      label:not([role='presentation']) {
        font-size: 0.8em;
        margin: 0;
        padding: 0 0 0 0.75em;
      }

      label[role='presentation'] {
        display: grid;
        z-index: 2;

        &::after {
          content: '';
          margin: auto;
        }
      }
    }

    &--checkbox {
      input {
        transform: scale(0.95);
      }

      input:checked + label[role='presentation']::after {
        opacity: 1;
      }

      input,
      label[role='presentation'] {
        font-size: 1.6em;
      }

      label[role='presentation'] {
        display: grid;
        border: 2px solid var(--c-goma-grey-disabled);

        &::after {
          content: '';
          opacity: 0;
          transform: translateY(-20%) translateX(2%) rotate(-42deg);
          border: 0.125em solid var(--c-goma-grey);
          border-top: 0;
          border-right: 0;
          width: 0.45em;
          height: 0.2em;
          transition: opacity 0.1s, transform 0.1s;
        }
      }

      &--single {
        max-width: calc(
          ((var(--g-col-size) + var(--g-gap)) * 6) - var(--g-gap)
        );

        input,
        label[role='presentation'] {
          font-size: 2.4em;
        }

        label[role='presentation'] {
          border: 1px solid var(--c-goma-grey-disabled);
        }

        label:not([role='presentation']) {
          padding: 0 0 0 1.5em;
        }
      }

      &-tag {
        display: grid;

        input,
        label {
          grid-column: 1;
          grid-row: 1;
          margin: 0;
        }

        input {
          width: 100%;
          height: 100%;
        }

        small {
          font-weight:500;
        }

        :checked + label {
          background: var(--c-goma-grey);
          color: var(--c-white);

          a {
            background: var(--c-mid-grey);
            transition: background 0.1s;
          }
        }

        label {
          background: var(--c-goma-grey-disabled);
          display: grid;
          grid-auto-flow: column;
          font-weight: 600;
          padding: 0.75em 1em;
          transition: background 0.1s, color 0.1s;

          a {
            background: rgba(white, 0.4);
            margin: -0.75em -1em -0.75em 1em;
            padding: inherit;
            display: grid;
            align-content: center;
            text-decoration: none;

            &::before {
              content: '';
              display: block;
              height: 0.75em;
              width: 0.75em;
              background: currentColor;
              -webkit-mask: url(data-url:../svgs/link.svg) no-repeat 50% 50%;
              mask: url(data-url:../svgs/link.svg) no-repeat 50% 50%;
              -webkit-mask-size: cover;
              mask-size: cover;
            }
          }
        }
      }
    }

    &--radio {
      align-items: center;
      display: grid;
      grid-auto-flow: column;

      :checked + label[role='presentation'] {
        color: var(--c-goma-grey);

        &::after {
          opacity: 1;
        }
      }

      label[role='presentation'] {
        border: 2px solid;
        border-radius: 50%;
        color: var(--c-goma-grey-disabled);
        transition: color 0.1s;

        &::after {
          border-radius: 50%;
          background: currentColor;
          height: 0.6666em;
          width: 0.6666em;
          opacity: 0;
          transition: opacity 0.1s;
        }
      }

      &-button {
        margin: 0 -1px 0 0;
        position: relative;
        z-index: 1;

        &:focus-within {
          z-index: 2;

          > input {
            outline: 2px solid #00a699;
          }
        }

        > input {
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 0;
          margin: 0;
          z-index: 1;
        }

        :checked + label {
          background: var(--c-goma-grey);
          color: var(--c-white);
          position: relative;
        }

        label {
          background: var(--c-white);
          border: 1px solid var(--c-goma-grey-disabled);
          font-size: 1.2em;
          font-weight: 700;
          margin: 0;
          padding: 0.75em 1.3em;
          position: relative;
          transition: background 0.1s, color 0.1s;
          z-index: 2;

          input:not([type="radio"]):not([type="checkbox"]) {
            background:transparent;
            border:0;
            border-bottom:1px solid;
            color:inherit;
            display:inline;
            font-size:1em;
            line-height:0.9;
            padding:0;
            width:3ch;
            min-width:3ch;
            text-align:center;

            &:focus {
              outline:none;
            }

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            &[type=number] {
              -moz-appearance: textfield;
            }
          }
        }
      }
    }

    &__help-text {
      font-size: 0.75rem;

      & ul {
        padding-left: 0;
        list-style-type: none;
      }
    }
  }

  &__group {
    > div {
      display: flex;
      flex-wrap: wrap;
      gap: 1em;
    }

    label:first-child {
      flex: 1 0 100%;
    }

    &#{&} {
      // grid-column-end: -5;
    }

    &--checkbox-tag {
      > div {
        gap: 0.5em;
      }
    }
    &--radio-button {
      > div {
        gap: 0;
      }
    }
  }

  &__exhibition-select-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  }

  &__exhibition-select {
  background: var(--c-goma-grey-disabled);
  cursor: pointer;
  display: flex;
  
    &__buildings {
      display: flex;
      gap: 0.5rem;
      font-size: 0.75rem;
    }

    &.active {
      background: var(--c-goma-grey);
  
      .form__exhibition-select__label {
      color: white;
      }
      
      .form__exhibition-select__link {
        background: var(--c-goma-grey-hover);
        
          svg {
            fill: white;
          }
      }
  
    }
    
    &__link {
      display: flex;
      place-content: center;
      place-items: center;
      padding: 0.5rem 1rem;
      background: #E4E6E9;
      
      svg {
        margin-top: -0.4rem;
      }
    }
  
    &__label {
      padding: 0.8em 1em;
    }
  }

}

.form__input.invalid {
  :where(input, textarea, select) {
    border: 2px solid var(--c-form-error) !important;
  }
}

.form__error-msg {
  margin-top: 0.75em;
  color: var(--c-form-error);
  background: url('data-url:/assets/svgs/error.svg') no-repeat;
  background-size: 1.3rem;
  background-position: left center;
}

.form__error-msg-text {
  padding-left: 1.6rem;
  text-align: left;
}

.button.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.form__error-shake {
  -webkit-animation: anim_shake 0.4s 1 linear;
  -moz-animation: anim_shake 0.4s 1 linear;
  -o-animation: anim_shake 0.4s 1 linear;
}
@-webkit-keyframes anim_shake {
  0% { -webkit-transform: translate(30px); }
  20% { -webkit-transform: translate(-30px); }
  40% { -webkit-transform: translate(15px); }
  60% { -webkit-transform: translate(-15px); }
  80% { -webkit-transform: translate(8px); }
  100% { -webkit-transform: translate(0px); }
}
@-moz-keyframes anim_shake {
  0% { -moz-transform: translate(30px); }
  20% { -moz-transform: translate(-30px); }
  40% { -moz-transform: translate(15px); }
  60% { -moz-transform: translate(-15px); }
  80% { -moz-transform: translate(8px); }
  100% { -moz-transform: translate(0px); }
}
@-o-keyframes anim_shake {
  0% { -o-transform: translate(30px); }
  20% { -o-transform: translate(-30px); }
  40% { -o-transform: translate(15px); }
  60% { -o-transform: translate(-15px); }
  80% { -o-transform: translate(8px); }
  100% { -o-origin-transform: translate(0px); }
}

// Spectific Form Overrides

//c9f64359-2136-49c0-96e3-a13663645059 - Donation form - Simple

#c9f64359-2136-49c0-96e3-a13663645059 {

  h2 {
    font-size: 2em;
    margin-bottom: 0;
  }

  .form__group--radio-button {
    & > div {
      place-content: space-between;

      & > div {
        flex: 1 1 auto;

        & label {
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }

}

//d71f4e47-5c1b-426d-86fc-6bdd2e7f0a5c - GVSO Suitability form

#d71f4e47-5c1b-426d-86fc-6bdd2e7f0a5c {

  .form__input-controls {
    margin-bottom: 64px;
  }

  .form__input.form__input--checkbox {
    width: 100%;
  }

  .form__qualification {
    display: grid;
    gap: var(--g-gap);
  }

  .form__input--radio-button label {
    font-size: 0.8rem;
  }

  .form__group.form__group {
    grid-column-end: none !important;
  }

}